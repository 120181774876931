import React, { useState, useEffect, useCallback } from "react";
import moment from "moment";

const style = {
  fontSize: "8px",
  marginLeft: "4px",
  alignSelf: "center",
}

interface IProps {
  className: string;
  time: any
}

export const OpenClose: React.FC<IProps> = (props: IProps) => {
  const [time, setTime] = useState(props.time);
  const [isOpen, setOpen] = useState(false);
  const [isAroundClock, setAroundClock] = useState(false)
  const [isWeekend, setWeekend] = useState(false)

  let day = moment().format("dddd").toLowerCase();
  let timeZ = moment().format("HH:mm");

  const changeOpen = useCallback(() => {
    if(!time) return setOpen(false);
    setAroundClock( time[day] === "00:00 - 23:59" )

    let value1 = time[day] === "Выходной" ? time[day] : time[day].slice(0, 5);
    let value2 = time[day] === "Выходной" ? time[day] : time[day].slice(8);
    
    if(value1 === '00:00' && value2 === '00:00') return setOpen(false);

    if (value1 === "Выходной") {
      setWeekend(true)
      return setOpen(false);
    }
    
    if (value1 < value2) return setOpen(value1 <= timeZ && timeZ <= value2);

    if (value1 <= timeZ && timeZ <= "23:59:59") return setOpen(true);
    if (value2 >= timeZ && timeZ >= "00:00:00") return setOpen(true);

    return setOpen(false);
  }, [time]);  // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => { setTime(props.time) }, [props.time]);
  useEffect(() => { changeOpen() }, [time, changeOpen]);

  return (
    <div className={props.className}>
      {isOpen ? (
        <div className="p-d-flex color-green">
          <span> 
            Открыто 
            <span style={{color: '#000', fontFamily: 'MontserratRegular'}}> {isAroundClock ? "" : ` до ${time[day].slice(8)}`} </span>
          </span>
          {isAroundClock && <span style={style}>(Круглосуточно)</span>}
        </div>
      ) : (
        <span className="color-red">
          Закрыто 
          <span style={{color: '#000', fontFamily: 'MontserratRegular'}}> { isWeekend ? '' : isAroundClock ? "" : time ? ` до ${time[day].slice(0, 5)}` : ''} </span>
        </span>
      )}
    </div>
  );
}

export default OpenClose;