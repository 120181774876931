import React, { useState } from "react";
import { AutoComplete } from "primereact/autocomplete";

import styles from "./style.module.css";
import { ICity } from "domain/types/city.type";

interface IComponentProps{
  data: Array<object>;
  onSelect: (id: string) => void;
}

const Search: React.FC<IComponentProps> = (props: any) => {
  const [selected, setSelected] = useState("");
  const [filtered, setFilter] = useState(undefined);

  const { data, onSelect } = props;

  const handleSearch = (event: any) => {
    setTimeout(() => {
      let filtered;
      if (!event.query.trim().length) {
        filtered = [...data];
      } else {
        filtered = data.filter((item: ICity) => {
          return item.name.toLowerCase().startsWith(event.query.toLowerCase());
        });
      }
      
      setFilter(filtered);
    }, 1000);
  };

  return (
    <div className="p-inputgroup">
      <AutoComplete
        className={`${styles.autocomplite}`}
        value={selected}
        suggestions={filtered}
        completeMethod={handleSearch}
        placeholder="Введите название города..."
        field="name"
        onChange={(e) => {
          if (typeof e.target.value === "object") {
            onSelect(e.target.value.id);
          }
          setSelected(e.value);
        }}
      />
    </div>
  );
}

export default Search;