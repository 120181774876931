import { RESTAURANT_REDUCE_SET__STOCKS_ALL_IN_MAIN } from "domain/constants/restaurant.constant";
import { SECRET_SHOPPER_REDUCE_SET__TASKS_ALL_IN_MAIN } from "domain/constants/secretShopper.constant";
import { RestaurantsReduceTypes } from "domain/types/restaurant.type";
import { ITask, SecretShopperReduceTypes } from "domain/types/secretShopper.type";

export const MAIN_SET__VIEWS = "MAIN_SET__VIEWS";
export const MAIN_SAGA_GET_HISTORY = "MAIN_SAGA_GET_HISTORY";

export interface ISetRecentViews {
  type: typeof MAIN_SET__VIEWS,
  payload: {
    views: any[]
  }
}

// Sagas
export function getRecentViews(history: { type: string, id: number }){
  return {
    type: MAIN_SAGA_GET_HISTORY,
    payload: { history }
  }
}

// Reducers
export function setTasksMain(tasks: ITask[] ): SecretShopperReduceTypes{
  return {
    type: SECRET_SHOPPER_REDUCE_SET__TASKS_ALL_IN_MAIN,
    payload: { tasks }
  }
}

export function setStocksMain(stocks: any ): RestaurantsReduceTypes{
  return {
    type: RESTAURANT_REDUCE_SET__STOCKS_ALL_IN_MAIN,
    payload: { stocks }
  }
}

export function setRecentViews(views: any[] ): ISetRecentViews{
  return {
    type: MAIN_SET__VIEWS,
    payload: { views  }
  }
}