import React from "react";
import { connect } from "react-redux";
import { showWindow } from "domain/actions/auth.action";
import { ContentLayout } from "./layouts";

import { Dialog } from "primereact/dialog";

import styles from "../../style.module.css";

export const CheckCodeRestoreWindow: React.FC = (props: any) => {
  const { isShow, showWindow } = props;

  return (
    <Dialog
      className={styles.authWindow + " " + styles.completedWindow}
      header="Подтверждение изменения пароля"
      visible={isShow}
      onHide={() => showWindow('checkedRestore', false)}
      modal
      draggable={false}
    >
      <div className="p-col-12 p-p-0">
        <ContentLayout />
      </div>
    </Dialog>
  );
}

const mapStateToProps = (state: any) => ({
  isShow: state.auth.windows.checkedRestore
})

export default connect(mapStateToProps, {showWindow})(CheckCodeRestoreWindow);
