import { put, call, fork, takeEvery, select } from 'redux-saga/effects'
import ApiController from 'domain/controllers/Api.controller';
import * as actions from "domain/actions/account.action";
import * as constants from "domain/constants/account.constant";
import * as types from "domain/types/account.type";
import * as reducer from 'domain/reducers/account.reduce';
import { setAuthenticated } from 'domain/actions/auth.action';
import { Result } from 'domain/types/other.type';
import { NorrController } from 'domain/controllers/Response.controller';
import BillingController from 'domain/controllers/Billing.controller';
import { checkTokenNotification } from './notifications.saga';
import Cookies from 'js-cookie';
import { regReplace } from './auth.saga';
import { IStockRestaurant } from 'domain/types/stock.type';
import { IReview } from 'domain/types/reviews.type';

const api = new ApiController();
const norr = new NorrController();
const billing = new BillingController();

export async function getStatusQrFetch(): Promise<Result<void>> {
  const qr = "896ae542-7565-4081-84ef-e580cf6c2238";
  return await billing.get(`/orders/status/${qr}`)
}

export async function getCurrentMeFetch(): Promise<Result<types.IAccount | undefined>> {
  return await api.get(`/accounts/current`)
}

export async function getSecretShopperFetch(account: types.IAccount): Promise<Result<boolean | undefined>> {
  return await api.get(`/accounts/shopper/${account.accountId}`)
}

export async function putAccountDataFetch(accountId: string, account: types.IPutAccount): Promise<Result<any>> {
  return await api.put(`/accounts/${accountId}`, account);
}

export async function putAccountCityFetch(accountId: string, cityId: string): Promise<Result<any>> {
  return await api.put(`/accounts/${accountId}/city`, {cityId});
}

export async function postSecretShopperFetch(account: types.IAccount, isActive: boolean): Promise<Result<boolean | undefined>> {
  return await api.post(`/sercretshopper`, { accountId: account.accountId, accept: isActive})
}

export async function putSecretShopperFetch(shopperId: string, isActive: boolean): Promise<Result<boolean | undefined>> {
  return await api.put(`/sercretshopper/${shopperId}`, { accept: isActive})
}

export async function uploadAvatarFetch(accountId: string, file: File): Promise<Result<undefined>> {
  const fd = new FormData();
  fd.append('avatar', file, file.name)
  return await api.put(`/accounts/${accountId}/upload/avatar`, fd);
}

export async function subscribeRestaurantSSFetch(restaurantId: string, token: string): Promise<Result<void>> {
  return await api.post('/account/subscriptions', { restaurantId, secretShopper: true, token })
}

export async function unSubscribeRestaurantSSFetch(subscribe: types.ISubscribe): Promise<Result<void>> {
  return await api.put(`/account/subscriptions/${subscribe.id}`, {...subscribe, token: "" })
}

export async function bindAccountPhoneFetch(phone: string): Promise<Result<any>> {
  return await api.put(`/accounts/update/phone`, {phone})
}

export async function checkAccountPhoneFetch(smsCode: string): Promise<Result<any>> {
  return await api.post(`/accounts/update/phone/check`, {smsCode})
}

export async function getAccountStocksFetch(): Promise<Result<IStockRestaurant[]>> {
  return await api.get(`/account/coupon`)
}

export async function getAccountReviewsFetch(): Promise<Result<IReview[]>> {
  return await api.get(`/3raza/review/account`)
}

export function* getCurrentMe(): any{
  yield put(actions.reqAccount(true))

  const response = yield call(getCurrentMeFetch)

  yield call(norr.processing, response, function *(){
    yield put(actions.setCurrentMe(response.value))
    yield put(setAuthenticated(true))
    yield call(getSecretShopper)
    yield call(checkTokenNotification, response.value.accountId)
    
    const difference = new Date().getTime() - new Date(response.value.createdAt).getTime();
    
    if(difference < 5000) {
      yield put(actions.showWindow('first_visit', true))
    }
  })

  if(response.type === 'error') {
    yield put(setAuthenticated(false))
    yield put(actions.clearCurrentMe())
  }

  yield put(actions.reqAccount(false))
}

export function* getSecretShopper(): any{
  const account =  yield select(reducer.getAccount)

  const secretShopper = yield call(getSecretShopperFetch, account)
  if(secretShopper.type === 'success'){
    yield put(actions.setSecretShopper(secretShopper.value))
  }
}

export function* toggleSecretShopper(action: any): any{
  const payload = action.payload;
  
  const account = yield select(reducer.getAccount)
  const secretShopper = yield select(reducer.getSecretShopper)

  if( !!secretShopper ) {
    const response = yield call(putSecretShopperFetch, secretShopper.id, payload.isActive)
    yield call(norr.processing, response, function *(){}, "Изменено участие в акции 'Тайный покупатель'")
  }
  if( !secretShopper ) {
    const response = yield call(postSecretShopperFetch, account, payload.isActive)
    yield call(norr.processing, response, function *(){}, "Изменено участие в акции 'Тайный покупатель'")
  }
  
  const resActive = yield call(getSecretShopperFetch, account)
  yield call(norr.processing, resActive, function *(){
    yield put(actions.setSecretShopper(resActive.value))
  })
}

export function* putAccount(action: any): any{
  const values = action.payload.values;
  const account = yield select(reducer.getAccount)

  let params = {
    name: values.name,
    surname: values.surname ? values.surname : null,
    email: values.email ? values.email : null,
    birthday: values.birthday ? new Date(values.birthday) : null,
    city: values.cityName ? {
      name: values.cityName.data.city,
      region: values.cityName.data.region_kladr_id,
      lat: values.cityName.data.geo_lat,
      long: values.cityName.data.geo_lon
    } : null
  } as types.IPutAccount;

  const response = yield call(putAccountDataFetch, account.id, params)

  yield call(norr.processing, response, function *(){
    yield call(getCurrentMe)
  }, "Профиль успешно изменен")

}

export function* putAccountCity(action: any): any {
  const cityId = action.payload.cityId;
  const account = yield select(reducer.getAccount)

  const response = yield call(putAccountCityFetch, account.id, cityId)

  yield call(norr.processing, response, function *(){
    yield call(getCurrentMe)
  }, "Профиль успешно изменен")
}

export function* uploadAvatar(action: any): any{
  yield put(actions.reqAccount(true))

  const file = action.payload.file

  const account = yield select(reducer.getAccount)
  const response = yield call(uploadAvatarFetch, account.id, file)

  yield call(norr.processing, response, function *(){
    yield call(getCurrentMe)
  }, "Фотография профиля изменена")

  yield put(actions.reqAccount(false))
}

export function* subscribeRestaurantSS(action: any): any{
  yield put(actions.reqAccount(true))

  const response = yield call(subscribeRestaurantSSFetch, action.payload.restaurantId, "")

  yield call(norr.processing, response, function *(){
    if(response.value) {
      yield put(actions.setTelegramToken(response.value))
      yield put(actions.showWindow('telegram', true))
    }
    yield call(getCurrentMe)
  })

  yield put(actions.reqAccount(false))
}

export function* unSubscribeRestaurantSS(action: any): any{
  const account = yield select(reducer.getAccount);
  yield put(actions.reqAccount(true))

  if(account && account?.subscriptions?.restaurants) {
    const subscriptions = account.subscriptions.restaurants;
    
    const index = subscriptions.map( (sub:any) => sub.restaurantId ).indexOf(action.payload.restaurantId);
    if(index < 0) return ;

    subscriptions[index].secretShopper = false;
    const response = yield call(unSubscribeRestaurantSSFetch, subscriptions[index])

    yield call(norr.processing, response, function *(){
      yield call(getCurrentMe)
    })
  }

  yield put(actions.reqAccount(false))
}

export function* bindPhone(action: any): any{
  const phone = action.payload.phone;
  let params = {
    phone: phone.replace(regReplace, ""),
  };

  const response = yield call(bindAccountPhoneFetch, params.phone);

  yield call(norr.processing, response, function *(){
    Cookies.set('phone', params.phone, {expires: new Date(new Date().getTime() + 300000)})
    yield put(actions.showWindow("check_phone", true))
  })

}

export function* checkPhone(action: any): any{
  const code = action.payload.code;

  const response = yield call(checkAccountPhoneFetch, code);
  
  yield call(norr.processing, response, function *(){
    yield put(actions.showWindow("bind_phone", false))
    yield put(actions.showWindow("check_phone", false))
    yield call(getCurrentMe)
  }, "Профиль успешно изменен")
}

export function* getAccountStocks(): any{
  yield put(actions.reqAccount(true))

  const response = yield call(getAccountStocksFetch);
  yield call(norr.processing, response, function *(){
    yield put(actions.setAccountStocks(response.value))
  }, "")

  yield put(actions.reqAccount(false))
}

export function* getAccountReviews(): any{
  yield put(actions.reqAccount(true))

  const response = yield call(getAccountReviewsFetch);
  yield call(norr.processing, response, function *(){
    yield put(actions.setAccountReviews(response.value))
  }, "")

  yield put(actions.reqAccount(false))
}

export function* watchAccount() {
  yield takeEvery(constants.ACCOUNT_SAGA_GET_CURRENT_ME, getCurrentMe)
  yield takeEvery(constants.ACCOUNT_SAGA_GET_SECRET_SHOPPER, getSecretShopper)
  yield takeEvery(constants.ACCOUNT_SAGA_POST_SECRET_SHOPPER, toggleSecretShopper)
  yield takeEvery(constants.ACCOUNT_SAGA_PUT, putAccount)
  yield takeEvery(constants.ACCOUNT_SAGA_PUT_CITY, putAccountCity)
  yield takeEvery(constants.ACCOUNT_SAGA_PATH_AVATAR, uploadAvatar)
  yield takeEvery(constants.ACCOUNT_SAGA_SUBSCRIBE_RESTAURANT_SS, subscribeRestaurantSS)
  yield takeEvery(constants.ACCOUNT_SAGA_UNSUBSCRIBE_RESTAURANT_SS, unSubscribeRestaurantSS)
  yield takeEvery(constants.ACCOUNT_SAGA_BIND_PHONE, bindPhone)
  yield takeEvery(constants.ACCOUNT_SAGA_CHECK_PHONE, checkPhone)
  yield takeEvery(constants.ACCOUNT_SAGA_GET_STOCKS, getAccountStocks)
  yield takeEvery(constants.ACCOUNT_SAGA_GET_REVIEWS, getAccountReviews)
}

export default function* AccountSagas() {
  yield fork(watchAccount)
}