import React, { useEffect } from "react";
import { connect } from "react-redux";

import styles from "../styles.module.css";
import { ScrollPanel } from "primereact/scrollpanel";
import { ITask } from "domain/types/secretShopper.type";
import CardTask from "./cards/task.card";
import { morph } from "utils/text.utils";
import DevelopmentCard from "pages/views/components/development.card";
import CardStock from "./cards/stock.card";
import { IStockRestaurant } from "domain/types/stock.type";
import { getTasksFilter } from "domain/actions/secretShopper.action";
import { useNavigate } from "react-router-dom";
import { getStocksFilter } from "domain/actions/restaurant.action";

export const Events: React.FC = (props: any) => {
  const { stocks, tasks, getTasksFilter, getStocksFilter } = props;
  let history = useNavigate();

  const countTask = tasks.count - tasks.rows.length;
  const countStock = stocks.count - stocks.rows.length;
  // const countNews = events.news.count - events.news.data.length;

  useEffect( () => {
    getTasksFilter(undefined, undefined, 3)
    getStocksFilter(undefined, undefined, 3)
  }, [] ) // eslint-disable-line react-hooks/exhaustive-deps

  const styleScroll = { width: '100%', height: '326px' }
  
  return (
    <div className={styles['events'] + ' block'}>
      <h2 className={styles['title']} id="events" >Что нового? Последние события путеводителя!</h2>
  
      <div className="p-col-12 p-p-0 p-d-flex p-flex-wrap">
        <div className="p-col-12 p-md-6 p-p-0" >
          <h4>Тайный покупатель</h4>
          <ScrollPanel style={styleScroll} className="custombar">
            {tasks.rows.length === 0 && <span className={styles['empty_list']}>Список пуст</span>}
            <div style={{ padding: '0 1em 1em 0'}} className="p-d-flex p-flex-wrap">
              {tasks.rows.map( (task: ITask) => <CardTask item={task} key={task.id}/> )}
            </div>  
          </ScrollPanel>

          <div 
            className={"p-col-12 p-p-0 p-text-right " + styles['card_next']}
            onClick={() => history('/main/tasks')}
          >Ещё {countTask} {morph(countTask, ['задание', 'задания', 'заданий'])} ...</div>
        </div>

        <div className="p-col-12 p-md-6 p-p-0" >
          <h4>Купоны</h4>
          <ScrollPanel style={styleScroll} className="custombar">
            {stocks.rows.length === 0 && <span className={styles['empty_list']}>Список пуст</span>}
            <div style={{ padding: '0 1em 1em 0'}} className="p-d-flex p-flex-wrap">
              {stocks.rows.map( (stock: IStockRestaurant) => <CardStock item={stock} key={stock.id}/> )}
            </div>  
          </ScrollPanel>

          <div 
            className={"p-col-12 p-p-0 p-text-right " + styles['card_next']}
            onClick={() => history('main/stocks')}
          >Ещё {countStock} {morph(countStock, ['купон', 'купона', 'купонов'])} ...</div>
        </div>

        <div className="p-col-12 p-md-6 p-xl-4 p-p-0 p-d-none" >
          <h4>Новости</h4>
          
          <DevelopmentCard />

          {/* <div className={"p-col-12 p-p-0 p-text-right " + styles['card_next']}>Ещё {countNews} {morph(countNews, ['статья', 'статьи', 'статей'])} ...</div> */}
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state: any) =>({
  tasks: state.main.tasks,
  stocks: state.main.stocks
});

export default connect(mapStateToProps, { getTasksFilter, getStocksFilter } )(Events);