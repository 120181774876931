import React from "react";
import { showWindow } from "domain/actions/auth.action";
import { connect } from "react-redux";

import styles from "./styles.module.css";
import { Logotype, Geolocation } from "components";
import MenuNav from "pages/views/components/menu";
import { AuthComponent } from "pages/components";
// import { Link } from "react-router-dom";
import { HashLink as Link } from 'react-router-hash-link';

const Main: React.FC = () => {
  return (
    <>
      <Link to='news' className='p-d-none'>Новости</Link>
      <Geolocation />
      <Link to='contacts' className='p-d-none'>Контакты</Link>
    </>
  )
}

const Home: React.FC = () => {
  return (
    <>
      <Link to='/#events' className={styles['header_link']}>Что нового?</Link>
      <Geolocation />
      <Link to='#aboutUs' className={styles['header_link']}>О нас</Link>
    </>
  )
}

interface IProps {
  type?: string;
}

export const Header: React.FC<IProps> = (props: IProps) => {
  const { type = 'main' } = props;

  return (
    <div className={styles['header']}>
      <Logotype className={styles['logotype']}/>

      <div className='p-d-none p-d-md-flex p-col-8 p-p-0 p-jc-around p-ai-baseline'>
        
        {type === 'main' && <Main />}
        {type === 'home' && <Home />}

      </div>
      
      <div className='p-d-none p-d-md-flex'>
        <AuthComponent />
      </div>

      <div className="p-d-flex p-ai-center p-jc-center p-d-md-none" style={{minWidth: '100px'}}>
        <Geolocation />
      </div>

      <div className='p-d-flex p-d-md-none' style={{ marginLeft: '42px' }}>
        <MenuNav />
      </div>
      
    </div>
  );
}

const mapStateToProps = (state: any) => ({
  user: state.account.account,
});

export default connect(mapStateToProps, { showWindow } )(Header);