import { Result } from "domain/types/other.type";
import { put } from "redux-saga/effects";
import * as norr from 'domain/actions/norr.action';

export class NorrController{
  *processing(response: Result<any>, callback: any, textSuccess?: string): IterableIterator<any>{
    
    if(response.type === 'success'){
      yield callback();

      if(textSuccess) yield put(norr.success(textSuccess))
      
      yield put(norr.clear());
      return true;
    }else if(response.type === 'error'){
      if(response.code === 401) return false;

      yield put(norr.error(response.error.message))

      yield put(norr.clear());

      if(response.code === 500) yield put(norr.showCriticalError(true))

      return false;
    }
  }

  *success(message?: string): IterableIterator<any>{
    if(message) yield put(norr.success(message))
    yield put(norr.clear());
  }

  *error(message: string): IterableIterator<any>{
    if(message) yield put(norr.error(message))
    yield put(norr.clear());
  }
}