import React from 'react';
import ReactDOM from 'react-dom';
import { HashRouter } from "react-router-dom";
import { Provider } from "react-redux";

import App from './App';
import reportWebVitals from './reportWebVitals';
import rootSaga from "domain/sagas";

import configureStore from "./domain/configureStore";
import { registerServiceWorker } from 'serviceWorker';
import { onMessageListener } from 'firebaseInit';

import './assets/styles/App.css';
import './assets/styles/windows.css';
import './assets/styles/datePicker.css';
import './assets/styles/index.css';
import './assets/styles/account.css';
import './assets/styles/dishes.css';
import './assets/styles/geolink.css';
import './assets/styles/gallery.css';
import './assets/styles/media.css';
import './assets/styles/tasks.css';
import './assets/styles/itemViewer.css';
import './assets/styles/form/inputs.css';
import './assets/styles/restaurant/stocks.restaurant.css';
// import './assets/styles/restaurant/swiper.restaurant.css';
import './assets/styles/tag.color.css';
import './assets/styles/start.css';
import './assets/styles/animations.css';
import './assets/styles/main.css';
import './assets/styles/light.css';

import './components/animation/geolocation.style.css';

import "primereact/resources/themes/saga-green/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import 'primeflex/primeflex.css';

import "./assets/fontAwesome";
import ErrorBoundary from 'components/errorBundl';

const store = configureStore()
store.runSaga(rootSaga)

ReactDOM.render(
  <HashRouter>
    <Provider store={store}>
      <ErrorBoundary>
        <App />
      </ErrorBoundary>
    </Provider>
  </HashRouter>,
  document.getElementById('root')
);

registerServiceWorker();
onMessageListener();
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();