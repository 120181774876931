import React from "react";
import styles from "./style.module.css";
import { Geolink } from "components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface IProps {
  className: string;
  classGeolink?: string;
  address: string;
  city: string;
  name: string;
  dataKey: string;
  distance: number | null;
}

export const AddressContact: React.FC<IProps> = (props: IProps) => {
  const { className, classGeolink = "", dataKey, city = "", address, name, distance } = props;
  return (
    <div className={className}>
      <div
        className={styles.text + " p-d-flex p-flex-column p-ai-start " + styles.address}
        style={{ position: "relative" }}
      >
        <span>{address}</span>
        <Geolink
          dataKey={dataKey}
          fullAddress={`${city}, ${address}, ${name}`}
          description={name}
          classGeolink={classGeolink}
        >
          {address}
        </Geolink>
        {distance && 
          <div className="p-ml-1">
            <FontAwesomeIcon icon={["fas", "walking"]} className={styles.icon + ""}/>
            <span className="p-m-0"> {(distance).toFixed(2)} kм</span>
          </div>
        }
      </div>

    </div>
  );
}

export default AddressContact;
