import { put, call, fork, takeEvery } from 'redux-saga/effects'
import ApiController from 'domain/controllers/Api.controller';
import * as actions from "domain/actions/owner.restaurant.action";
import * as constants from "domain/constants/owner.constant";
import { showWindow } from "domain/actions/owner.action";
import { Result } from 'domain/types/other.type';
import { NorrController } from 'domain/controllers/Response.controller';
import { ISchema } from 'domain/types/owner.type';

const api = new ApiController();
const norr = new NorrController();

export async function getQuestionnairesFetch(restaurantId: string): Promise<Result<ISchema[]>> {
  return await api.get(`/restaurants/${restaurantId}/questionnaires`)
}

export async function getQuestionnaireByIdFetch(questionnaireId: string): Promise<Result<ISchema>>{
  return await api.get(`/restaurants/questionnaires/${questionnaireId}`);
}

export async function updateQuestionnaireCommentFetch(questionnaireId: string, data: {comment: string}): Promise<Result<ISchema>>{
  return await api.put(`/restaurants/questionnaires/${questionnaireId}/comment`, data);
}

export function* getQuestionnaires(action: any): any{
  const restaurantId = action.payload.restaurantId;
  
  const response = yield call(getQuestionnairesFetch, restaurantId);
  yield call(norr.processing, response, function *(){
    yield put(actions.setQuestionnairesRestaurant(response.value))
    yield put(showWindow("restaurantQuestionnaires", true))
  })

}

export function* getQuestionnaireById(action: any): any{
  const questionnaireId = action.payload.questionnaireId;

  const response = yield call(getQuestionnaireByIdFetch, questionnaireId);
  yield call(norr.processing, response, function *(){
    yield put(actions.setQuestionnaireById(response.value))
    yield put(showWindow("questionnaire", true))
  })
}

export function* updateQuestionnaireComment(action: any): any{
  const questionnaireId = action.payload.questionnaireId;
  const comment = action.payload.comment;

  const response = yield call(updateQuestionnaireCommentFetch, questionnaireId, { comment });
  yield call(norr.processing, response, function *(){
    yield put(showWindow("questionnaire", false))
  })
}

export function* watch() {
  yield takeEvery(constants.OWNER_RESTAURANT_SAGA_GET_QUESTIONNAIRES, getQuestionnaires)
  yield takeEvery(constants.OWNER_RESTAURANT_SAGA_GET_QUESTIONNAIRE_BY_ID, getQuestionnaireById)
  yield takeEvery(constants.OWNER_RESTAURANT_SAGA_UPDATE_QUESTIONNAIRE_COMMENT, updateQuestionnaireComment)
}

export default function* Sagas() {
  yield fork(watch)
}