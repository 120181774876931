import { put, call, fork, takeEvery } from 'redux-saga/effects'
// import ApiController from 'domain/controllers/Api.controller';
import { NorrController } from 'domain/controllers/Response.controller';
import * as actions from "domain/actions/coupon.action";
import * as constants from "domain/constants/coupon.constant";
// import * as types from "domain/types/coupon.type";
// import * as reducer from 'domain/reducers/city.reduce';
import { Result } from 'domain/types/other.type';

import ShopperController from 'domain/controllers/Shopper.controller';

// const api = new ApiController();
const shopper = new ShopperController();
const norr = new NorrController();

export async function getCouponByIdFetch(id: string): Promise<Result<any>> {
  return await shopper.get(`/coupons/template/${id}`)
}

export async function restCouponFetch(data: any): Promise<Result<any>> {
  return await shopper.put(`/coupons/template/${data.id}`, data)
}

export async function deleteCouponFetch(couponId: string): Promise<Result<any>> {
  return await shopper.delete(`/coupons/template/${couponId}`)
}

export function* getCouponById(action: any): any{
  const response = yield call(getCouponByIdFetch, action.payload.couponId)

  if(response.type === "error") return action.payload.cb();

  yield call(norr.processing, response, function *(){
    yield put(actions.setCoupon(response.value))
  })

}

export function* restCoupon(action: any): any{
  const response = yield call(restCouponFetch, action.payload.data)

  yield call(norr.processing, response, function *(){
    yield put(actions.setCoupon(response.value))
  })

}

export function* deleteCoupon(action: any): any{
  const {couponId} = action.payload
  const response = yield call(deleteCouponFetch, couponId)

  yield call(norr.processing, response, function *(){
    yield window.location.reload();
  })
}

export function* watchCities() {
  yield takeEvery(constants.COUPON_SAGA_GET_BY_ID, getCouponById)
  yield takeEvery(constants.COUPON_SAGA_RESTART, restCoupon)
  yield takeEvery(constants.COUPON_SAGA_DELETE, deleteCoupon)
}

export default function* CitySagas() {
  yield fork(watchCities)
}