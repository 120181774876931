import * as constant from "domain/constants/email.constant";
import * as types from "domain/types/email.type";

// Sagas
export function sendWriteToUS(values: types.IWriteToUsForm, cb: () => void): types.EmailSagaTypes{
  return {
    type: constant.EMAIL_SAGA_SEND_WRITE_TO_US,
    payload: { values, cb }
  }
}

// Reducers

export function reqEmail(isLoad: boolean): types.EmailReduceTypes{
  return {
    type: constant.EMAIL_CHECKOUT_REQUEST,
    payload: { loading: isLoad }
  }
}

export function reqErrorEmail(error: any): types.EmailReduceTypes{
  return {
    type: constant.EMAIL_CHECKOUT_REQUEST_ERROR,
    payload: { error }
  }
}

export function reqSuccessEmail(success: any): types.EmailReduceTypes{
  return {
    type: constant.EMAIL_CHECKOUT_REQUEST_SUCCESS,
    payload: { success }
  }
}