import React from "react";
import { logOut } from "domain/actions/auth.action";

import styles from "../style.module.css";
import { connect } from "react-redux";

export const SignOut: React.FC = (props: any) => {
  const { logOut } = props;

  return (
    <button
      className={styles.btn}
      onClick={logOut}
    >
      {props.user ? props.user.name : "Выйти"}
    </button>
  );
}

const mapStateToProps = (state: any) => ({
  user: state.user.current,
});

export default connect(mapStateToProps, {logOut})(SignOut);
