import React from "react";
import { connect } from "react-redux";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import styles from "../style.module.css";

interface IComponentProps{
  onClick: () => void;
}

const GeoBtn: React.FC<IComponentProps> = (props: any) => {
  const { onClick, geoError } = props;

  const errorText = <div className={styles.geoError}>{geoError}</div>;

  return (
    <div className={styles.geoBtn} onClick={onClick}>
      {!!geoError && errorText}
      <FontAwesomeIcon
        icon={["fas", "map-marker-alt"]}
        style={{ fontSize: "30px", marginRight: "10px" }}
      />

      <span> Определить по геолокации </span>
    </div>
  );
}

const mapStateToProps = (state: any) => ({
  geoError: state.cities.geoError,
});
export default connect(mapStateToProps)(GeoBtn);