import React from "react";
import { connect } from "react-redux";
import { showWindow } from "domain/actions/auth.action";

import styles from "../../../style.module.css";

interface IProps {
  isPage?: boolean;
  showPage?: (name: string) => void;
}

const FooterComponent: React.FC<IProps> = (props: any) => {
  const { showWindow, isPage = false, showPage = () => {} } = props;

  const handleClick = () => {
    if(isPage) return showPage('register')
    showWindow('auth', false);
    showWindow('register', true);
  }

  return (
    <div className="p-col-12 p-text-center p-p-0 p-p-md-2">
      <span className={styles.footer_form}>
        У вас нет учетной записи?{" "}
        <b onClick={handleClick} className="cursor-pointer">Зарегистрироваться</b>
      </span>
    </div>
  );
}

export default connect(null, {showWindow})(FooterComponent);