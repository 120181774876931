import * as constant from "domain/constants/favorites.constant";
import * as types from "domain/types/favorites.type";
import { IRestaurant } from "domain/types/restaurant.type";

export function getRestaurants(): types.FavoritesSagaTypes{
  return {
    type: constant.FAVORITES_SAGA_GET_RESTAURANTS,
  }
}

export function favoriteRestaurant(restaurantId: string): types.FavoritesSagaTypes{
  return {
    type: constant.FAVORITES_SAGA_FAVORITE_RESTAURANT,
    payload: { restaurantId }
  }
}

export function unFavoriteRestaurant() {
  return {
    type: constant.FAVORITES_SAGA_UNFAVORITE_RESTAURANT,
  }
}

export function setRestaurants(restaurants: Array<IRestaurant> ): types.FavoritesReduceTypes{
  return {
    type: constant.FAVORITES_REDUCE_SET_RESTAURANTS,
    payload: { data: restaurants }
  }
}

export function reqSuccess(success: boolean): types.FavoritesReduceTypes{
  return {
    type: constant.FAVORITES_CHECKOUT_REQUEST_SUCCESS,
    payload: { success }
  }
}

export function requireLoading(isLoad: boolean): types.FavoritesReduceTypes{
  return {
    type: constant.FAVORITES_CHECKOUT_REQUEST,
    payload: { loading: isLoad }
  }
}