import React, { Suspense, useEffect } from 'react';
import {
  Routes,
  Route
} from "react-router-dom";
import {connect} from "react-redux";
import { ScrollToTop } from 'components';
import { ProvideAuth } from "./config/ProvideAuth";

import StartPage from 'pages/views/public/start';

import { getCurrentMe } from 'domain/actions/account.action';
import { getCities } from 'domain/actions/city.action';
import { getCategories } from 'domain/actions/restaurant.action';
import { getHashtags } from 'domain/actions/hashtags.action';
import { ShopperSubscribeWindow } from 'pages/components';
import TelegramWindow from 'pages/views/private/secretShopper/client/windows/telegram.window';
import MenuSidebar from 'pages/views/public/main.old/menu.sidebar';
import { setVisibleMenu } from 'domain/actions/other.action';
import { DynamicRestaurantBreadcrumb } from 'components/breadcrumb';
import AuthWindows from 'pages/components/authorization/windows';
import { CookiesDocsWin } from 'pages/views/components/cookies.docs.win';
import FirstVisitWindow from 'pages/views/private/accountPersonal/first.visit.window';

const Norr = React.lazy(() => import('pages/views/components/norr'));

const Restaurant = React.lazy(() => import("pages/views/public/restaurant"));
const MenuPage = React.lazy(() => import("pages/views/public/restaurant/menu"));

const AllStocks = React.lazy(() => import("pages/views/public/all/stocks"));
const AllTasks = React.lazy(() => import("pages/views/public/all/tasks"));
const AllReviews = React.lazy(() => import("pages/views/public/all/reviews"));
const Review = React.lazy(() => import("pages/views/public/review"));
const Contact = React.lazy(() => import("pages/views/public/contact"));
const SocialLogin = React.lazy(() => import("pages/views/public/social.login"));
const Main = React.lazy(() => import("pages/views/public/Main"));
const PrivateMain = React.lazy(() => import("pages/views/private"));
const Account = React.lazy(() => import("pages/views/private/account"));
const Personal = React.lazy(() => import("pages/views/private/accountPersonal"));
const Stock = React.lazy(() => import("pages/views/private/stocks"));
const Reviews = React.lazy(() => import("pages/views/private/reviews"));
const SecretShopper = React.lazy(() => import("pages/views/private/secretShopper"));
const Favorites = React.lazy(() => import("pages/views/private/favorites"));
const Coupon = React.lazy(() => import("pages/views/public/coupon"));
const StockPage = React.lazy(() => import("pages/views/public/stock"));
const CookieDoc = React.lazy(() => import('pages/views/public/docs/cookies'));
const UserAgreement = React.lazy(() => import('pages/views/public/docs/user.agreement.docs'));
const DataProcessing = React.lazy(() => import('pages/views/public/docs/data.processing.docs'));
const RegistrationPage = React.lazy(() => import('pages/views/public/registration'));

const RestaurantsPage = React.lazy(() => import("pages/views/public/all/restaurants"));

const RestaurantOwner = React.lazy(() => import("pages/views/private/owner/restaurant"));
const ReceiptsOwner = React.lazy(() => import("pages/views/private/owner/receipts"));
const CreateRestaurant = React.lazy(() => import("pages/views/private/owner/restaurant/create"));
const UpdateRestaurant = React.lazy(() => import("pages/views/private/owner/restaurant/update"));
const SecretShopperOwner = React.lazy(() => import("pages/views/private/owner/secretShopper"));

export const routesBreadcrumb = [
  { path: "/", breadcrumb: " " },
  { path: "/social/login", breadcrumb: " " },
  { path: "/main", breadcrumb: " " },
  { path: "/main/review", breadcrumb: " " },
  { path: "/main/contact", breadcrumb: " " },
  { path: "/main/stocks", breadcrumb: " " },
  { path: "/main/tasks", breadcrumb: " " },
  { path: "/main/stock", breadcrumb: " " },
  { path: "/main/stock/:stockId", breadcrumb: " " },
  { path: "/main/restaurant/:restaurantId", breadcrumb: DynamicRestaurantBreadcrumb },
  { path: "/main/restaurant/:restaurantId/reviews", breadcrumb: "Отзывы" },
  { path: "/main/restaurant", breadcrumb: `Заведения`, pathname: "/main/restaurants" },
  { path: "/main/restaurant/menu", breadcrumb: "Отзывы" },
  { path: "/main/restaurant/menu/:restaurantId", breadcrumb: DynamicRestaurantBreadcrumb },
  { path: "/private", breadcrumb: " " },
  { path: "/private/account", breadcrumb: " " },
  { path: "/private/account/personal", breadcrumb: " " },
  { path: "/private/account/stock", breadcrumb: " " },
  { path: "/private/account/secret_shopper", breadcrumb: " " },
  { path: "/private/account/secret_shopper/tasks", breadcrumb: " " },
  { path: "/private/account/secret_shopper/shopper", breadcrumb: " " },
  { path: "/private/account/secret_shopper/archive", breadcrumb: " " },
  { path: "/private/account/secret_shopper/setting", breadcrumb: " " },
  { path: "/private/account/favorites", breadcrumb: " " },
  { path: "/private/account/owner", breadcrumb: " " },
  { path: "/private/account/owner/secret_shopper", breadcrumb: " " },
  { path: "/private/account/owner/restaurant", breadcrumb: " " },
  { path: "/private/account/owner/receipts", breadcrumb: " " },
] as any[];


function RoutesMap(props: any) {
  const { visibleMenu, setVisibleMenu } = props;
  const {getCurrentMe, getCities, getCategories, getHashtags} = props;

  useEffect( ()=> { getCategories() }, [getCategories] );
  useEffect( ()=> { getHashtags() }, []) // eslint-disable-line react-hooks/exhaustive-deps
  useEffect( ()=> { getCities() }, [getCities] );
  useEffect( ()=> { getCurrentMe() }, [getCurrentMe] );

  return (
    <ProvideAuth>
      <ScrollToTop>
        <Routes>

          <Route path="coupon" element={<Suspense fallback={<></>}> <Coupon /> </Suspense>}>
            <Route path=":couponId" element={<Suspense fallback={<></>}> <Coupon /> </Suspense>} /> 
          </Route>

          <Route path="/main" element={<Suspense fallback={<></>}> <Main /> </Suspense>}>
            <Route path="stock" element={<Suspense fallback={<></>}> <StockPage /> </Suspense>}>
              <Route path=":stockId" element={<Suspense fallback={<></>}> <StockPage /> </Suspense>} />
            </Route>

            <Route path="registration" element={<Suspense fallback={<></>}> <RegistrationPage /> </Suspense>}> </Route>
            
            <Route path="restaurant" element={<Suspense fallback={<></>}> <Restaurant /> </Suspense>}>
              <Route path=":restaurantId" element={<Suspense fallback={<></>}> <Restaurant /> </Suspense>}>
                <Route path=":tab" element={<Suspense fallback={<></>}> <Restaurant /> </Suspense>} />
              </Route>
            </Route>
            <Route path="restaurant/menu" element={<Suspense fallback={<></>}> <MenuPage /> </Suspense>}>
              <Route path=":restaurantId" element={<Suspense fallback={<></>}> <MenuPage /> </Suspense>} />
            </Route>
            

            <Route path="restaurants" element={<Suspense fallback={<></>}> <RestaurantsPage /> </Suspense>} />

            <Route path="contact" element={<Suspense fallback={<></>}> <Contact /> </Suspense>} />
            <Route path="review" element={<Suspense fallback={<></>}> <Review /> </Suspense>} />

            <Route path="stocks" element={<Suspense fallback={<></>}> <AllStocks /> </Suspense>} />
            <Route path="tasks" element={<Suspense fallback={<></>}> <AllTasks /> </Suspense>} />
            <Route path="reviews" element={<Suspense fallback={<></>}> <AllReviews /> </Suspense>} />

            <Route path="docs/cookies" element={<Suspense fallback={<></>}> <CookieDoc /> </Suspense>} /> 
            <Route path="docs/userAgreement" element={<Suspense fallback={<></>}> <UserAgreement /> </Suspense>} /> 
            <Route path="docs/dataProcessing" element={<Suspense fallback={<></>}> <DataProcessing /> </Suspense>} />
          </Route>

          <Route path="private" element={<Suspense fallback={<></>}> <PrivateMain /> </Suspense>}>
            <Route path="account" element={<Suspense fallback={<></>}> <Account /> </Suspense>} />
            <Route path="account/personal" element={<Suspense fallback={<></>}> <Personal /> </Suspense>} />
            <Route path="account/stock" element={<Suspense fallback={<></>}> <Stock /> </Suspense>} />
            <Route path="account/reviews" element={<Suspense fallback={<></>}> <Reviews /> </Suspense>} />
            <Route path="account/secret_shopper" element={<Suspense fallback={<></>}> <SecretShopper /> </Suspense>}>
              <Route path=":tab" element={<Suspense fallback={<></>}> <SecretShopper /> </Suspense>} />
            </Route>
            <Route path="account/favorites" element={<Suspense fallback={<></>}> <Favorites /> </Suspense>} />
            <Route path="account/owner/secret_shopper" element={<Suspense fallback={<></>}> <SecretShopperOwner /> </Suspense>}>
              <Route path=":tab" element={<Suspense fallback={<></>}> <SecretShopperOwner /> </Suspense>} />
            </Route>
            <Route path="account/owner/restaurant" element={<Suspense fallback={<></>}> <RestaurantOwner /> </Suspense>} />
            <Route path="account/owner/restaurant/create" element={<Suspense fallback={<></>}> <CreateRestaurant /> </Suspense>} />
            <Route path="account/owner/restaurant/:restaurantId" element={<Suspense fallback={<></>}> <UpdateRestaurant /> </Suspense>} />

            <Route path="account/owner/receipts" element={<Suspense fallback={<></>}> <ReceiptsOwner /> </Suspense> } />
          </Route>
          
          
          {/* <Route path="/" element={<Home />} /> */}
          <Route path="/" element={<StartPage />} />
          <Route path="/social/login" element={<Suspense fallback={<></>}> <SocialLogin /> </Suspense>} />

        </Routes>
      </ScrollToTop>

      <Suspense fallback={<></>}>
        <Norr />
        <ShopperSubscribeWindow />
        <TelegramWindow />
        <MenuSidebar visible={visibleMenu} setVisible={setVisibleMenu} />
        <AuthWindows />
        <CookiesDocsWin />
        <FirstVisitWindow />
      </Suspense>
    </ProvideAuth>
  );
}

const mapStateToProps = (state: any) => ({
  visibleMenu: state.other.menuSide,
})

export default connect(mapStateToProps, {getCurrentMe, getCities, getCategories, getHashtags, setVisibleMenu })(RoutesMap);