import React from "react";
import { connect } from "react-redux";
import { showWindow } from "domain/actions/secretShopper.action";

import { Dialog } from "primereact/dialog";
import Subscribe from "pages/views/private/secretShopper/client/subscribe";
import { useNavigate } from "react-router-dom";

export const ShopperSubscribeWindow: React.FC<any> = (props: any) => {
  const { isShow, showWindow } = props;
  let history = useNavigate();

  const handleClose = () => {
    showWindow('subscribe', false)
    history(`/private/account/secret_shopper`);
  }

  return (
    <Dialog
      header="Тайный покупатель"
      visible={isShow}
      onHide={handleClose}
      modal
      draggable={false}
    >
      <Subscribe />
    </Dialog>
  );
};

const mapStateToProps = (state: any) => ({
  isShow: state.secretShopper.windows.subscribe
})

export default connect(
  mapStateToProps, 
{showWindow})(ShopperSubscribeWindow);
