import React from "react";

interface IProps {
  className: string;
  phone: string;
}

export const PhoneContact: React.FC<IProps> = (props: IProps) => {
  const { className, phone } = props;
  return (
    <div className={className}>
      
        <span>{phone}</span>
      
    </div>
  );
}

export default PhoneContact;
