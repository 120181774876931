import * as constant from "domain/constants/hashtags.constant";
import * as types from "domain/types/hashtags.type";

// Sagas
export function getHashtags(): types.HashtagsSagaTypes{
  return {
    type: constant.HASHTAGS_SAGA_GET_ALL,
  }
}

// Reducers
export function setHashtags(hashtags: Array<types.IHashtag>): types.HashtagsReduceTypes{
  return {
    type: constant.HASHTAGS_REDUCE_SET_ALL,
    payload: { data: hashtags }
  }
}

export function request(isLoad: boolean): types.HashtagsReduceTypes{
  return {
    type: constant.HASHTAGS_CHECKOUT_REQUEST,
    payload: { loading: isLoad }
  }
}