import React from 'react';
import { connect } from "react-redux";

import styles from '../style.module.css';
import { subscription } from 'domain/actions/secretShopper.action';

export const Subscribe: React.FC = (props: any) => {
  const { subscription } = props;

  const handleSecretShopper = () => subscription();

  return (
    <div className="p-d-flex p-jc-center p-ai-center p-col-12 account-subscribe">
      <div className="p-d-flex p-flex-column">
          <span className={styles.subscribe_header}>Начните участвовать в акции <strong>“Тайный покупатель”</strong>.</span>

          <span className={styles.subscribe_description}>Хотите посетить своё любимое заведение, с очень хорошим <strong>cashback</strong>’ом, 
            и оценить атмосферу/качество сервиса, тогда эта акция именно для Вас. <br/> 
            <i><u>Принимайте участие</u></i> в акции, выполняйте задания, и получайте кэшбэк <strong>70%-80%</strong> от среднего чека заведения.
          </span>

          <button className="form-button p-mt-5" onClick={handleSecretShopper}>
            Принять участие
          </button>
      </div>
    </div>
  );
};

export default connect(
  null, 
  {
    subscription
  })(Subscribe);