import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useCookies } from "react-cookie";

import { getCityById, getDefaultCity } from "domain/actions/city.action";
import { ICity } from "domain/types/city.type";
import { GeoWindow } from "./components";

import styles from "./style.module.css";
import { AwaitGeolocation } from "components/animation/geolocation";

interface IComponentProps{
  current?: ICity | null;
  getCityById?: (id: string) => void;
}

const Geolocation: React.FC<IComponentProps> = (props: any) => {
  const { current, loading, getCityById, getDefaultCity } = props;
  const [show, toggleShow] = useState(false);
  const [cookies] = useCookies();
    
  useEffect(() => {
    if (cookies.s_city) getCityById(cookies.s_city);
    else getDefaultCity();
  }, [cookies.s_city, getCityById, getDefaultCity]);

  return (
    <div className={styles.city}>
      <span 
        className="p-d-flex p-jc-center p-jc-md-start p-flex-wrap" 
        onClick={() => toggleShow(true)}
        style={{ cursor: "pointer" }}
      >
        {/* <GeoMarker /> */}
        <span className={`p-mr-2 ${styles.title} p-d-none p-d-md-inline`}>Мой город</span>
        <span id="currentCity" >
          <u> {current ? current?.name?.toUpperCase() : "Не выбран" } </u>
        </span>
      </span>

      <GeoWindow show={show} toggleShow={toggleShow} />
      {/* <GeoPopup showFindCity={toggleShow} /> */}
      <AwaitGeolocation loading={loading}/>
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  current: state.cities.current,
  loading: state.cities.geolocationLoad
});

export default connect(mapStateToProps, { getCityById, getDefaultCity })( Geolocation );







