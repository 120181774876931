import React, { useState } from "react";
import { connect } from "react-redux";
import { showWindow } from "domain/actions/auth.action";
import { FormLayout } from "./layouts";

import { Dialog } from "primereact/dialog";

import styles from "../../style.module.css";
// import YoutubeViewer from "../youtube.window";
import RoleLayout from "./layouts/role.layout";

export const RegWindow: React.FC = (props: any) => {
  // const [ youtube, setYoutube ] = useState(false);
  const [ role, setRole ] = useState<string | null>(null);
  const { isShow, showWindow } = props;

  // useEffect( () => {
  //   if(isShow) {
  //     setYoutube(isShow)
  //   }
    
  // }, [isShow] ) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Dialog
      className={styles.authWindow}
      header="Регистрация"
      visible={isShow}
      onHide={() => {
        setRole(null)
        showWindow('register', false)
      }}
      modal
      draggable={false}
    >
      
      <div className="p-col-12 p-p-0">
        { role === null 
          ? <RoleLayout onChange={setRole}/>
          : <FormLayout role={role} setRole={setRole}/>
        }
      </div>

      {/* <YoutubeViewer show={youtube} onHide={setYoutube} /> */}
    </Dialog>
  );
}

const mapStateToProps = (state: any) => ({
  isShow: state.auth.windows.register
})

export default connect(mapStateToProps, {showWindow})(RegWindow);
