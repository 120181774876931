import * as constant from "domain/constants/stock.constant";
import * as types from "domain/types/stock.type";

// Sagas
export function getStocks(): types.StocksSagaTypes{
  return {
    type: constant.STOCK_SAGA_GET_ALL,
  }
}

export function getStockById(stockId: string): types.StocksSagaTypes{
  return {
    type: constant.STOCK_SAGA_GET_BY_ID,
    payload: { stockId }
  }
}

export function getRestaurantStockById(couponId: string){
  return {
    type: constant.STOCK_SAGA_GET_BY_ID_FOR_RESTAURANT,
    payload: { couponId }
  }
}

export function completeStock(code: string, couponId: string){
  return {
    type: constant.STOCK_SAGA_COMPLETE,
    payload: { code, couponId }
  }
}

export function getSmsCodeStock(stockId: string): types.StocksSagaTypes{
  return {
    type: constant.STOCK_SAGA_SET_SMS_CODE,
    payload: { stockId }
  }
}

export function postPhoneClientStock(phone: string): types.StocksSagaTypes{
  return {
    type: constant.STOCK_SAGA_POST_PHONE_CLIENT,
    payload: { phone }
  }
}

export function addStockForAccount(stockId: string, cb: () => void): types.StocksSagaTypes{
  return {
    type: constant.STOCK_SAGA_ADD_STOCK_FOR_ACCOUNT,
    payload: { stockId, cb }
  }
}

// Reducers
export function setStocks(stocks: Array<types.IStock> ): types.StocksReduceTypes{
  return {
    type: constant.STOCK_REDUCE_SET_ALL,
    payload: { data: stocks }
  }
}

export function selectStock(stock: types.IStock): types.StocksReduceTypes{
  return {
    type: constant.STOCK_REDUCE_SELECT,
    payload: { stock }
  }
}

export function selectRestaurantStock(stock: types.IStock): types.StocksReduceTypes{
  return {
    type: constant.STOCK_REDUCE_SELECT_RESTAURANT,
    payload: { stock }
  }
}

export function clearStock(): types.StocksReduceTypes{
  return {
    type: constant.STOCK_REDUCE_CLEAR
  }
}

export function reqStocks(isLoad: boolean): types.StocksReduceTypes{
  return {
    type: constant.STOCK_CHECKOUT_REQUEST,
    payload: { loading: isLoad }
  }
}

export function reqErrorStocks(error: any): types.StocksReduceTypes{
  return {
    type: constant.STOCK_CHECKOUT_REQUEST_ERROR,
    payload: { error }
  }
}

export function showWindow(window: string, isShow: boolean ): types.StocksReduceTypes {
  return {
    type: constant.STOCK_REDUCE_IS_SHOW_WINDOW,
    payload: {
      window: window,
      isShow: isShow
    }
  }
}