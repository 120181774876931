import { put, call, fork, takeEvery, select } from 'redux-saga/effects';
import ApiController from 'domain/controllers/Api.controller';
import * as actions from "domain/actions/auth.action";
import * as constants from "domain/constants/auth.constant";
import * as types from 'domain/types/auth.type';
import * as reducer from 'domain/reducers/auth.reduce';
import { error, clear } from 'domain/actions/norr.action';
import { getCurrentMeFetch } from "domain/sagas/account.saga";
import { clearCurrentMe, setCurrentMe } from 'domain/actions/account.action';
import { NorrController } from 'domain/controllers/Response.controller';
import { Result } from 'domain/types/other.type';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';

const api = new ApiController();
const norr = new NorrController();
export const regReplace = /[\s()-]/gm;

export async function authorizationFetch(values: types.IAuthForm): Promise<Result<types.IAuthLogin | undefined>> {
  return await api.post(`/auth/login`, values)
}

export async function registrationFetch(values: types.IRegister): Promise<Result<string>> {
  return await api.post(`/accounts/register`, values)
}

export async function checkedCodeFetch(values: types.ICheckCodeForm): Promise<Result<any>> {
  return await api.post(`/accounts/check`, values)
}

export async function repeatCodeFetch(regId: string): Promise<Result<void>> {
  return await api.get(`/accounts/repeat/check/${regId}`)
}

export async function sendRestoreCodeFetch(phone: string): Promise<Result<any>> {
  return await api.post(`/accounts/restore/check`, { phone })
}

export async function postRestorePasswordFetch(values: types.IRestoreForm): Promise<Result<any>> {
  return await api.post(`/accounts/restore`, values)
}

export async function logoutFetch(): Promise<boolean> {
  return true;
}

export function* login(action: any): any{
  const host = window.location.protocol + "//" + window.location.host;
  const values = action.payload;
  const redirect =  yield select(reducer.getRedirect)
  values.login = values.login.replace(regReplace, "");

  const response = yield call(authorizationFetch, values);

  if(response.type === "success"){
    localStorage.setItem("access_token", response.value.access_token);
    localStorage.setItem("refresh_token", response.value.refresh_token);
  
    yield put(actions.reqErrorAuth(null))
    yield put(actions.showWindow('auth', false))
  
    const responseMe = yield call(getCurrentMeFetch);
    
    if(responseMe.type === "success") {
      yield put(actions.setAuthenticated(true))
      yield put(setCurrentMe(responseMe.value))

      yield put(actions.setRedirect(""))

      if(redirect) window.location.replace(`${host}/#${redirect}`)
    };
  }else {
    yield put(error("Логин или пароль не совпадают"))
    yield put(clear());

    yield put(actions.setAuthenticated(false))
  }
}

export function* register(action: any): any{
  const values = action.payload;
  let params = {
    phone: values.phone.replace(regReplace, ""),
    password: values.password,
    checkPassword: values.confirm,
    city: {
      name: values.city.data.city,
      region: values.city.data.region_kladr_id,
      lat: values.city.data.geo_lat,
      long: values.city.data.geo_lon
    },
    role: values.role
  };

  const response = yield call(registrationFetch, params);

  yield call(norr.processing, response, function *(){
    Cookies.set('regId', response.value, {expires: new Date(new Date().getTime() + 300000)})
    Cookies.set('phone', params.phone, {expires: new Date(new Date().getTime() + 300000)})
    Cookies.set('pass', params.password, {expires: new Date(new Date().getTime() + 300000)})
    yield put(actions.reqErrorAuth(null))
    yield put(actions.showWindow("register", false))
    yield put(actions.showWindow("checked", true))
  })

}

export function* repeatCode(action: any): any{
  const regId = Cookies.get('regId');
  // const regId = '43647ea8-d112-4f15-9ce9-bb06fde1abc2'
  console.log("regId", regId)
  if(!regId) return

  const response = yield call(repeatCodeFetch, regId);

  yield call(norr.processing, response, function *(){ })
}

export function* checked(action: any): any{
  const values = action.payload.values;

  const check = yield call(checkedCodeFetch, values);

  yield call(norr.processing, check, function *(){
    yield put(actions.reqErrorAuth(null))
    yield put(actions.showWindow("checked", false))

    const phone = Cookies.get('phone')
    const password = Cookies.get('pass')
    if(!!phone && !!password) {
      yield put(actions.login( { 
        login: phone,
        password, 
        remember: false } ) )
    }else {
      yield put(actions.showWindow("auth", true))
    }

    
  })
}

export function* checkedRestore(action: any): any{
  const phone = action.payload.phone;
  const response = yield call(sendRestoreCodeFetch, phone);

  yield call(norr.processing, response, function *(){
    yield put(actions.setDataRestore(phone))
    yield put(actions.showWindow("restore", false))
    yield put(actions.showWindow("checkedRestore", true))
  })
}

export function* restore(action: any): any{
  const values = action.payload;
  const response = yield call(postRestorePasswordFetch, values);
  
  yield call(norr.processing, response, function *(){
    yield put(actions.showWindow("checkedRestore", false))
    yield put(actions.showWindow("auth", true))
  }, "Пароль удачно изменен")

}

export function* logout(): any{
  yield call(logoutFetch);

  localStorage.removeItem("access_token");
  localStorage.removeItem("refresh_token");
  
  yield put(actions.setAuthenticated(false))
  yield put(clearCurrentMe())
  
  window.location.reload();
}

export function* clearError(): any{
  yield put(actions.reqErrorAuth(null))
}

export function* watchAuth() {
  yield takeEvery(constants.AUTH_REDUCE_IS_SHOW_WINDOW, clearError)
  yield takeEvery(constants.AUTH_ACTION_LOGIN, login)
  yield takeEvery(constants.AUTH_ACTION_REGISTRATION, register)
  yield takeEvery(constants.AUTH_ACTION_CHECK_CODE, checked)
  yield takeEvery(constants.AUTH_ACTION_LOGOUT, logout)
  yield takeEvery(constants.AUTH_SAGA_RESTORE_PASSWORD_CHECKED, checkedRestore)
  yield takeEvery(constants.AUTH_SAGA_RESTORE_PASSWORD, restore)
  yield takeEvery(constants.AUTH_SAGA_REPEAT_CODE, repeatCode)
}

export default function* authSagas() {
  yield fork(watchAuth)
}