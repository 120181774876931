import React from "react";
import { useMediaQuery } from "react-responsive";
import { Link } from "react-router-dom";

interface IProps {
  className: string;
  width?: string;
  height?: string;
}

export const Logotype: React.FC<IProps> = (props: IProps) => {
  const { className, width = "100", height = "48" } = props;

  const isMobile = useMediaQuery({
    query: '(max-width: 768px)'
  })

  return (
    <Link to="/">
      <img
        src={process.env.PUBLIC_URL + "/images/3_.png"}
        alt="3Raza"
        className={className + " logotype-3raza"}
        width={isMobile ? 72 : width}
        height={isMobile ? 36 : height}
      />
    </Link>
  );
};

export default Logotype;