import * as constant from "domain/constants/account.constant";
import * as types from "domain/types/account.type";
import { IReview } from "domain/types/reviews.type";
import { IStockRestaurant } from "domain/types/stock.type";

// Sagas
export function subscribeRestaurantSS(restaurantId: string) {
  return {
    type: constant.ACCOUNT_SAGA_SUBSCRIBE_RESTAURANT_SS,
    payload: { restaurantId }
  }
}

export function unSubscribeRestaurantSS(restaurantId: string) {
  return {
    type: constant.ACCOUNT_SAGA_UNSUBSCRIBE_RESTAURANT_SS,
    payload: { restaurantId }
  }
}

export function getCurrentMe(): types.AccountSagaTypes{
  return {
    type: constant.ACCOUNT_SAGA_GET_CURRENT_ME,
  }
}

export function getAccountById(accountId: string): types.AccountSagaTypes{
  return {
    type: constant.ACCOUNT_SAGA_GET_BY_ID,
    payload: { accountId }
  }
}

export function getAccountStocks(): types.AccountSagaTypes{
  return {
    type: constant.ACCOUNT_SAGA_GET_STOCKS
  }
}

export function getAccountReviews() {
  return {
    type: constant.ACCOUNT_SAGA_GET_REVIEWS
  }
}

export function getActiveShopper(): types.AccountSagaTypes{
  return {
    type: constant.ACCOUNT_SAGA_GET_SECRET_SHOPPER,
  }
}

export function editAccountData(values: types.IPutAccount): types.AccountSagaTypes{
  return {
    type: constant.ACCOUNT_SAGA_PUT,
    payload: { values }
  }
}

export function updateAccountCity(cityId: string): types.AccountSagaTypes{
  return {
    type: constant.ACCOUNT_SAGA_PUT_CITY,
    payload: { cityId }
  }
}

export function bindAccountPhone(phone: string): types.AccountSagaTypes{
  return {
    type: constant.ACCOUNT_SAGA_BIND_PHONE,
    payload: { phone }
  }
}

export function checkBindPhone(code: string): types.AccountSagaTypes{
  return {
    type: constant.ACCOUNT_SAGA_CHECK_PHONE,
    payload: { code }
  }
}

export function registerAccount(smsCode: string): types.AccountSagaTypes{
  return {
    type: constant.ACCOUNT_SAGA_CHECK,
    payload: { smsCode }
  };
}

export function checkSmsCodeAccount(regAccount: types.IPostAccount): types.AccountSagaTypes{
  return {
    type: constant.ACCOUNT_SAGA_POST,
    payload: { regAccount }
  };
}

export function pathSecretShopperAccount(isActive: boolean): types.AccountSagaTypes{
  return {
    type: constant.ACCOUNT_SAGA_POST_SECRET_SHOPPER,
    payload: { isActive }
  };
}

export function pathAccount(account: types.IPostAccount): types.AccountSagaTypes{
  return {
    type: constant.ACCOUNT_SAGA_PATH,
    payload: { account }
  };
}

export function uploadAvatar(accountId: string, file: File): types.AccountSagaTypes{
  return {
    type: constant.ACCOUNT_SAGA_PATH_AVATAR,
    payload: { file }
  };
}


// Reducers
export function setCurrentMe(account: types.IAccount ): types.AccountReduceTypes{
  return {
    type: constant.ACCOUNT_REDUCE_SET,
    payload: { account }
  }
}

export function clearCurrentMe(): any{
  return {
    type: constant.ACCOUNT_REDUCE_CLEAR,
  }
}

export function setSecretShopper(data: any | null): types.AccountReduceTypes{
  return {
    type: constant.ACCOUNT_REDUCE_SET_SECRET_SHOPPER,
    payload: { secretShopper: data }
  }
}

export function reqAccount(isLoad: boolean): types.AccountReduceTypes{
  return {
    type: constant.ACCOUNT_CHECKOUT_REQUEST,
    payload: { loading: isLoad }
  }
}

export function reqErrorAccount(error: any): types.AccountReduceTypes{
  return {
    type: constant.ACCOUNT_CHECKOUT_REQUEST_ERROR,
    payload: { error }
  }
}

export function showWindow(window: string, isShow: boolean ): types.AccountReduceTypes {
  return {
    type: constant.ACCOUNT_REDUCE_IS_SHOW_WINDOW,
    payload: {
      window: window,
      isShow: isShow
    }
  }
}

export function setTelegramToken(token: string): types.AccountReduceTypes {
  return {
    type: constant.ACCOUNT_REDUCE_SET_TELEGRAM_TOKEN,
    payload: { token: token }
  }
}

export function setAccountStocks(stocks: IStockRestaurant[]): types.AccountReduceTypes{
  return {
    type: constant.ACCOUNT_REDUCE_SET_STOCKS,
    payload: { stocks }
  }
}

export function setAccountReviews(reviews: IReview[]): types.AccountReduceTypes{
  return {
    type: constant.ACCOUNT_REDUCE_SET_REVIEWS,
    payload: { reviews }
  }
}