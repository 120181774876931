import React from 'react';

interface IProps {
  loading: boolean;
}

export const AwaitGeolocation: React.FC<IProps> = (props: IProps) => {
  const { loading } = props;

  return (
    <>
      {loading  && 
        <div className="await-geolocation">
          <div>

            <div className="loading">Определение местоположения</div>

            <div className="globe-container p-d-none">

              <div className="globe">
                  <div className="globe-sphere"></div>
                  <div className="globe-outer-shadow"></div>
                  <div className="globe-worldmap">
                      <div className="globe-worldmap-back"></div>
                      <div className="globe-worldmap-front"></div>
                  </div>
                  <div className="globe-inner-shadow"></div>
              </div>
            </div>

          </div>
        </div>
      }
    </>
    
  );
};






