import React from 'react';
import { connect } from "react-redux";

import { Dialog } from 'primereact/dialog';
import { checkSubscribe, showWindow } from 'domain/actions/secretShopper.action';
import { useNavigate } from 'react-router-dom';

export const TelegramWindow: React.FC<any> = (props: any) => {
  const { visible, token, showWindow, checkSubscribe } = props;
  const history = useNavigate();
  const telegramBot = process.env.REACT_APP_TELEGRAM_BOT;

  const handleClose = () => {
    showWindow('telegram', false)
    showWindow('subscribe', false)
      history(`/private/account/secret_shopper`)

    checkSubscribe()
  }

  return (
    <Dialog
      className="window-border-bottom p-col-12 p-p-0 p-md-8 p-xl-4"
      header="Внимание!"
      visible={visible}
      onHide={handleClose}
      modal maximizable
      draggable={false}
    >
      <p>
        Для оперативного получения оповещений пожалуйста подпишитесь на нашего telegram-бота
        <br/>
        Активируйте нашего бота  
        <a href={`https://t.me/${telegramBot}?start=${token}`} target="_blank" rel="noopener noreferrer" className='p-ml-1'>
          @threeraza_bot
        </a> 
      </p>
      
    </Dialog>
  );
};

const mapStateToProps = (state: any) => ({
  visible: state.secretShopper.windows.telegram,
  token: state.account.telegramToken
})

export default connect(mapStateToProps, {showWindow, checkSubscribe})(TelegramWindow);