export const ACCOUNT_ACTION = "ACCOUNT_ACTION";
export const ACCOUNT_REDUCE_AUTHENTICATED = "ACCOUNT_REDUCE_AUTHENTICATED";

export const ACCOUNT_SAGA_GET_CURRENT_ME = "ACCOUNT_SAGA_GET_CURRENT_ME";
export const ACCOUNT_SAGA_GET_BY_ID = "ACCOUNT_SAGA_GET_BY_ID";
export const ACCOUNT_SAGA_GET_SECRET_SHOPPER = "ACCOUNT_SAGA_GET_SECRET_SHOPPER";
export const ACCOUNT_SAGA_POST_SECRET_SHOPPER = "ACCOUNT_SAGA_POST_SECRET_SHOPPER";
export const ACCOUNT_SAGA_POST = "ACCOUNT_SAGA_POST";
export const ACCOUNT_SAGA_PATH = "ACCOUNT_SAGA_PATH";
export const ACCOUNT_SAGA_PUT = "ACCOUNT_SAGA_PUT";
export const ACCOUNT_SAGA_PUT_CITY = "ACCOUNT_SAGA_PUT_CITY";
export const ACCOUNT_SAGA_PUT_PHONE = "ACCOUNT_SAGA_PUT_PHONE";
export const ACCOUNT_SAGA_BIND_PHONE = "ACCOUNT_SAGA_BIND_PHONE";
export const ACCOUNT_SAGA_CHECK_PHONE = "ACCOUNT_SAGA_CHECK_PHONE";
export const ACCOUNT_SAGA_CHECK = "ACCOUNT_SAGA_CHECK";
export const ACCOUNT_SAGA_PATH_AVATAR = "ACCOUNT_SAGA_PATH_AVATAR";
export const ACCOUNT_SAGA_GET_STOCKS = "ACCOUNT_SAGA_GET_STOCKS";
export const ACCOUNT_SAGA_GET_REVIEWS = "ACCOUNT_SAGA_GET_REVIEWS";

export const ACCOUNT_SAGA_SUBSCRIBE_RESTAURANT_SS = "ACCOUNT_SAGA_SUBSCRIBE_RESTAURANT_SS";
export const ACCOUNT_SAGA_UNSUBSCRIBE_RESTAURANT_SS = "ACCOUNT_SAGA_UNSUBSCRIBE_RESTAURANT_SS";

export const ACCOUNT_REDUCE_CLEAR = "ACCOUNT_REDUCE_CLEAR";
export const ACCOUNT_REDUCE_SET = "ACCOUNT_REDUCE_SET";
export const ACCOUNT_REDUCE_SET_SECRET_SHOPPER = "ACCOUNT_REDUCE_SET_SECRET_SHOPPER";

export const ACCOUNT_REDUCE_SET_TELEGRAM_TOKEN = "ACCOUNT_REDUCE_SET_TELEGRAM_TOKEN";

export const ACCOUNT_REDUCE_IS_SHOW_WINDOW = "ACCOUNT_REDUCE_IS_SHOW_WINDOW";

export const ACCOUNT_CHECKOUT_REQUEST = "ACCOUNT_CHECKOUT_REQUEST";
export const ACCOUNT_CHECKOUT_REQUEST_ERROR = "ACCOUNT_CHECKOUT_REQUEST_ERROR";

export const ACCOUNT_REDUCE_SET_STOCKS = "ACCOUNT_REDUCE_SET_STOCKS";
export const ACCOUNT_REDUCE_SET_REVIEWS = "ACCOUNT_REDUCE_SET_REVIEWS";