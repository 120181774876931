import React from "react";
import { connect } from "react-redux";
import { Form, Field } from "react-final-form";
import { TextField } from "components/form/fields";
import { requiredValidator, composeValidators } from "components/form/validators";
import { checkCode } from 'domain/actions/auth.action';

import styles from "../../../style.module.css";
import { ICheckCodeForm } from "domain/types/auth.type";

const ContentLayout: React.FC = (props: any) => {
  const { checkCode } = props;
  let formData = { smsCode: "" };

  const onSubmit = (values: ICheckCodeForm) => checkCode(values);

  return (
    <div className="p-col-12">
      <span
        className={
          styles.content_text +
          " p-col-12 p-p-0 p-mt-4 p-mb-4 p-d-block p-text-center"
        }
      >
        Мы отправили на указанный вами номер смс-сообщение с кодом подтверждения
      </span>

      <Form
        onSubmit={onSubmit}
        initialValues={formData}
        render={({ handleSubmit, form, submitting, pristine, values }) => (
          <form onSubmit={handleSubmit}>
            <div className="p-mt-1 p-mb-3">
              <Field
                validate={composeValidators(requiredValidator)}
                name="smsCode"
                label="Код подтверждения из смс-сообщения:"
                render={TextField}
                settings={{
                  placeholder: "Введите код подтверждения из смс-сообщения",
                }}
              />
            </div>

            <div className="p-mt-1">
              <button
                type="submit"
                className={styles.form_button}
                disabled={submitting}
              >
                Подтвердить регистрацию
              </button>
            </div>
          </form>
        )}
      />
    </div>
  );
}

export default connect(null, {checkCode})(ContentLayout);