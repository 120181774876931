import React from "react";

import styles from "../../../style.module.css";

export const ContentLayout: React.FC = () => {
  return (
    <div className="p-col-12">
      <span
        className={
          styles.content_text_h1 + " p-col-12 p-p-0 p-d-block p-text-center"
        }
      >
        Спасибо!
      </span>
      <span
        className={
          styles.content_text +
          " p-col-12 p-p-0 p-mt-4 p-mb-4 p-d-block p-text-center"
        }
      >
        Вы успешно зарегистрировались на нашем сервисе
      </span>
    </div>
  );
}
