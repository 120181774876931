import React from 'react';
import moment from "moment";
import { locale, addLocale } from "primereact/api";
import { dataPickerSetting } from "utils/settings";
import RoutesMap from "routes";

// // Styles must use direct files imports
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/zoom';
import 'swiper/css/scrollbar';
import 'swiper/css/grid';

import 'video-react/dist/video-react.css'

import "./assets/styles/itemViewer.css";
import 'react-dadata/dist/react-dadata.css';
import '@smastrom/react-rating/style.css'
import PwaInstallWin from 'pages/components/pwa/install.window';

moment.locale("ru");
addLocale("ru", dataPickerSetting);
locale("ru");

const App:React.FC = (props: any) => 
  <>

    <PwaInstallWin />
    <RoutesMap />
    
  </>
  

export default App;