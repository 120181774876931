import React from "react";
import { isHTTPS } from "utils/regexps";

export const UserButton: React.FC<any> = (props: any) => {
  const { user } = props;

  const rank = user?.rank?.rank ? user.rank.rank : null;

  
  if(!user) return <></>

  return (
    <button {...props} style={{ position: 'relative' }}>
      <img 
        src={`${isHTTPS(user?.avatar) ? user.avatar : '/api/files/' + user?.avatar}`}
        alt={`${user?.name}`}
        onError={(e: any) => e.target.src = `${process.env.PUBLIC_URL}/images/avatar_default.png` }
      />
      <i className="pi pi-angle-down" style={{fontSize: '12px'}}></i>

      {rank?.icon && (
        <img 
          src={`/api/files${rank.icon}`} 
          alt="Ранг" 
          style={{
            position: 'absolute',
            right: '-6px',
            bottom: '-3px',
            width: '10px',
            height: '10px'
          }}
        />
      ) }
    </button>
  );
}

export default UserButton;