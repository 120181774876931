import { put, call, fork, takeEvery, select } from 'redux-saga/effects'
import ApiController from 'domain/controllers/Api.controller';
import * as actions from "domain/actions/stock.action";
import * as constants from "domain/constants/stock.constant";
import * as types from "domain/types/stock.type";
import { getCurrentRestaurant } from 'domain/reducers/restaurant.reduce';
import { getCurrentStock } from 'domain/reducers/stock.reduce';
import { Result } from 'domain/types/other.type';
import { NorrController } from 'domain/controllers/Response.controller';
import { getRestaurantByIdFetch } from './restaurant.saga';
import { selectRestaurant } from 'domain/actions/restaurant.action';

const api = new ApiController();
const norr = new NorrController();

export async function getStocksFetch(): Promise<Result<Array<types.IStock>>> {
  return await api.get(`/coupons`)
}

export async function getStockByIdFetch(couponId: string): Promise<Result<types.IStock | undefined>> {
  return await api.get(`/coupon/${couponId}`)
}

export async function getRestaurantStockByIdFetch(stockId: string): Promise<Result<types.IStock | undefined>> {
  return await api.get(`/restaurants_coupons/${stockId}`)
}

export async function completeStockFetch( data: { code: string, couponId: string } ): Promise<Result<types.IStock | undefined>> {
  return await api.put(`/restaurants_coupons/complete`, data)
}

export async function postPhoneClientFetch(data: any): Promise<null | undefined> {
  return await api.post(`/phoneclient`, data)
}

export async function addStockForAccountFetch(data: { couponId: string }): Promise<Result<types.IStock | undefined>> {
  return await api.post(`/account/coupon/add`, data)
}

export function* getStocks(): any{
  yield put(actions.reqStocks(true))

  const response = yield call(getStocksFetch)

  yield call(norr.processing, response, function *(){
    yield put(actions.setStocks(response.value))
  })

  yield put(actions.reqStocks(false))
}

export function* getStockById(action: any): any{
  yield put(actions.reqStocks(true))

  const response = yield call(getStockByIdFetch, action.payload.stockId)
  yield call(norr.processing, response, function *(){
    yield put(actions.selectStock(response.value))
  })

  yield put(actions.reqStocks(false))
}

export function* getRestaurantStockById(action: any): any{
  yield put(actions.reqStocks(true))

  const response = yield call(getRestaurantStockByIdFetch, action.payload.couponId)
  yield call(norr.processing, response, function *(){
    console.log(response.value)
    yield put(actions.selectRestaurantStock(response.value))
  })

  yield put(actions.reqStocks(false))
}

export function* completeStock(action: any): any{

  const response = yield call(completeStockFetch, action.payload)
  yield call(norr.processing, response, function *(){
    yield call(getRestaurantStockById, { payload: { couponId: action.payload.couponId } })
  })
}

export function* getSmsCode(): any{
  yield put(actions.showWindow('phone', true))
}

export function* postPhoneClient(action: any): any{
  yield put(actions.reqStocks(true))

  const stock = yield select(getCurrentStock)
  const restaurant = yield select(getCurrentRestaurant)

  let data = {
    accept: true,
    stockId: stock.id,
    restaurantId: restaurant.id,
    phone: action.payload.phone
  };

  const response = yield call(postPhoneClientFetch, data)

  yield call(norr.processing, response, function *(){
    yield put(actions.showWindow('phone', false))
    yield put(actions.showWindow('smsCode', true))
  })
    
  yield put(actions.reqStocks(false))
}

export function* refreshStockRestaurant(action: any): any{
  yield put(actions.reqStocks(true))

  const response = yield call(getRestaurantByIdFetch, action.payload.restaurantId)

  yield call(norr.processing, response, function *(){
    yield put(selectRestaurant(response.value))
  })
  
  yield put(actions.reqStocks(false))
}

export function* addStockForAccount(action: any): any{
  yield put(actions.reqStocks(true))

  const restaurant = yield select(getCurrentRestaurant)

  const couponId = action.payload.stockId;
  const cb = action.payload.cb
  const response = yield call(addStockForAccountFetch, { couponId })
  yield call(norr.processing, response, function *(){
    yield call(refreshStockRestaurant, { payload: { restaurantId: restaurant.id } })
    cb()
  })
  
  if(response.type === 'error') yield call(refreshStockRestaurant, { payload: { restaurantId: restaurant.id } })

  yield put(actions.reqStocks(false))
}

export function* watchStocks() {
  yield takeEvery(constants.STOCK_SAGA_GET_ALL, getStocks)
  yield takeEvery(constants.STOCK_SAGA_GET_BY_ID, getStockById)
  yield takeEvery(constants.STOCK_SAGA_POST_PHONE_CLIENT, postPhoneClient)
  yield takeEvery(constants.STOCK_SAGA_SET_SMS_CODE, getSmsCode)
  yield takeEvery(constants.STOCK_SAGA_ADD_STOCK_FOR_ACCOUNT, addStockForAccount)
  yield takeEvery(constants.STOCK_SAGA_GET_BY_ID_FOR_RESTAURANT, getRestaurantStockById)
  yield takeEvery(constants.STOCK_SAGA_COMPLETE, completeStock)
}

export default function* StocksSagas() {
  yield fork(watchStocks)
}