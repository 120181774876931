import React from "react";
import { showWindow } from "domain/actions/auth.action";
import { connect } from "react-redux";
import FooterComponent from "pages/components/footer"

import styles from "../styles.module.css";


export const Footer: React.FC = () => {
  
  return (
    <div className={styles['footer']}>
      <FooterComponent />
    </div>
  );
}

const mapStateToProps = (state: any) => ({
  user: state.account.account,
});

export default connect(mapStateToProps, { showWindow } )(Footer);