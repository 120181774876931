import React from "react";
import { connect } from "react-redux";
import { showWindow } from "domain/actions/auth.action";
import { Dialog } from "primereact/dialog";

import styles from "../../style.module.css";
import { useNavigate } from "react-router-dom";

export const SecretShopperWindow: React.FC = (props: any) => {
  const { isShow, showWindow } = props;
  const navigator = useNavigate();

  const handleClose = () => showWindow('secretShopper', false);
  const handleClick = () => {
    handleClose()
    navigator("/private/account/secret_shopper/shopper")
  }

  return (
    <Dialog
      className={styles.authWindow + " " + styles.completedWindow}
      header="Тайный покупатель"
      visible={isShow}
      onHide={handleClose}
      modal
      draggable={false}
    >
      <div className="p-col-12 p-p-0">
        <span className={ styles.content_text_h1 + " p-col-12 p-p-0 p-d-block p-text-center" } >
          Поздравляем!
        </span>
        <span className={ styles.content_text + " p-col-12 p-p-0 p-mt-4 p-mb-4 p-d-block p-text-center" } >
          Вы успешно зарегистрировались на нашем сервисе, и мы вам предлагаем <strong>ознакомиться с инструкцией</strong> по условиям акции <strong>"Тайный покупатель"</strong>
        </span>
        
        <p 
          style={{color: '##0069c2', cursor: 'pointer'}}
          onClick={handleClick}>
            <u>Перейти на страницу "Тайного покупателя"</u>
        </p>

        <div className="p-mt-1">
          <button className={styles.other_btn} onClick={handleClose}>
            Закрыть
          </button>
        </div>

      </div>
    </Dialog>
  );
}

const mapStateToProps = (state: any) => ({
  isShow: state.auth.windows.secretShopper
})

export default connect(mapStateToProps, {showWindow})(SecretShopperWindow);
