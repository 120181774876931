import React from "react";
import { isHTTPS } from "utils/regexps";

interface IProps {
  className: string;
  image: string;
  onClick: () => void;
  children: React.ReactNode;
}

export const Logo: React.FC<IProps> = (props: IProps) => {
  const { image, className, onClick, children } = props;
  const img = `${isHTTPS(image) ? image : '/api/files/' + image}`
  return (
    <div className={className} onClick={onClick}>
      <div
        className="p-col-12 p-p-0"
        style={{
          backgroundImage: image ? `url(${img})` : `url(${process.env.PUBLIC_URL}/images/Empty.png)`,
          backgroundSize: "cover",
          backgroundPosition: "center"
        }}
      ></div>
      {children}
    </div>
  );
}

export default Logo;
