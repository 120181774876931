import React from "react";
import { connect } from "react-redux";
import { getCityByCoord } from "domain/actions/city.action";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const GeoMarker: React.FC = (props: any) => {
  const { getCityByCoord } = props;
  
  return (
    <FontAwesomeIcon
      onClick={() => getCityByCoord()}
      icon={["fas", "map-marker-alt"]}
      style={{ fontSize: "18px", marginTop: "-7px", marginRight: "10px" }}
    />
  );
}

export default connect(null, { getCityByCoord })(GeoMarker);