import { IRestaurant } from "domain/types/restaurant.type";
import React from "react";

import styles from "../../styles.module.css";
import { isHTTPS } from "utils/regexps";
import { toLengthString } from "utils/toLengthString";
import { useNavigate } from "react-router-dom";

interface IProps {
  item: IRestaurant | any
}

export const CardRestaurant: React.FC<IProps> = (props: IProps) => {
  const { item } = props;
  let history = useNavigate();

  const redirect = () => {
    if(item) history(`/main/restaurant/${item.id}`)
  };

  return (
    <div className="p-p-1 p-col-12 p-md-6 p-xl-3">
      <div className={'block ' + styles['card']} style={{maxHeight: '128px', padding: '16px'}}>
        <h4 className={styles['card_type']}>Заведение</h4>
        <div className="p-col-12 p-p-0 p-d-flex">
          <img 
            src={`${isHTTPS(item?.logo) ? item?.logo : '/api/files/' + item?.logo}`}
            onError={(e: any) => e.target.src='/api/admin/files/default'} 
            alt={item.name}
            className={styles['card_image']}
          />

          <div className="p-ml-2" style={{width: '100%'}}>
            <h3 className={styles['card_title']}>{item.name}</h3>
            <span className={styles['card_description']}> {toLengthString(`${item.city.name}, ${item.address}`, 36)} </span>
            <div className={"p-col-12 p-p-0 p-text-right " + styles['card_next']} onClick={redirect}>Продолжить...</div>
          </div>
        </div>

        
      </div>

      
    </div>
  )
}

export default CardRestaurant