import React, {useState, useEffect} from "react";
import { connect } from "react-redux";
import { repeatCode, showWindow } from "domain/actions/auth.action";
import { ContentLayout } from "./layouts";

import { Dialog } from "primereact/dialog";

import styles from "../../style.module.css";
import RepeatBtnLayout from "./layouts/repeat.btn.layout";

export const CheckCodeWindow: React.FC = (props: any) => {
  const { isShow, showWindow, repeatCode } = props;
  const [ sec, setSec ] = useState<number>(50)

  const repeat = () => {
    repeatCode()
    setSec(50)
  }

  useEffect(() => {
    let interval = null as any;
    
    if (sec > 0 && isShow) {
      interval = setInterval(() => {
        setSec((sec: number) => sec - 1);
      }, 1000);
    } else {
      clearInterval(interval);
    }
    return () => {
      clearInterval(interval);
    };
  }, [sec, isShow]);

  return (
    <Dialog
      className={styles.authWindow + " " + styles.completedWindow}
      header="Подтверждение регистрации"
      visible={isShow}
      onHide={() => showWindow('checked', false)}
      modal
      draggable={false}
    >
      <div className="p-col-12 p-p-0">
        <ContentLayout />

        <div className="p-pr-2 p-pl-2">
          <RepeatBtnLayout onClick={ repeat } second={sec} />
        </div>
      </div>
    </Dialog>
  );
}

const mapStateToProps = (state: any) => ({
  isShow: state.auth.windows.checked
})

export default connect(mapStateToProps, {showWindow, repeatCode})(CheckCodeWindow);
