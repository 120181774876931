import axios from "axios";
import { Result } from "domain/types/other.type";
import { AuthController } from "./Auth.controller";

class ApiController {
  private Auth: AuthController;
  
  constructor() {
    this.Auth = new AuthController();
  }

  getBaseUrl(): any {
    const prefix = "/api"

    return prefix;
  }  

  private getHeaders(params?: any) {
    const token = this.Auth.getAccessTokenStorage();

    let headers = {
      timeout: 500000,
      headers: {
        "Content-Type": "application/json",
        "Authorization": ""
      },
      params: params,
      baseURL: this.getBaseUrl()
    };
  
    if(token) headers.headers.Authorization = `Bearer ${token}`;

    return headers;
  }

  private async isErrorAuth(err: any) {
    if(err.response?.status === 422) {
      this.Auth.clearTokens();
      window.location.reload();
    }

    if(err.response?.status === 401) {
      try {
        await this.Auth.refreshAccessToken()
      } catch (error) { 
        return false;
      }
      return true;
    };
    return false;
  }

  private async withRefreshToken(): Promise<void> {
    const checked = this.Auth.checkedAccessToken()
  
    if(!!sessionStorage.getItem('isRefresh')) {
      await this.Auth.sleep(300)
    }

    if(!checked && checked !== null) {
      sessionStorage.setItem('isRefresh', 'true');
      return await this.Auth.refreshAccessToken()
        .then(() => sessionStorage.setItem('isRefresh', 'false') )
        .catch(() => sessionStorage.setItem('isRefresh', 'false') )
    };
  }

  async get(url: string, params?: any): Promise<Result<any>>{
    await this.withRefreshToken();

    try{
      const response = await axios.get(url, this.getHeaders(params))
      return { type: 'success', value: response.data };
    } catch(e: any){
      const isRefresh = await this.isErrorAuth(e);
      const error = e.response ? e.response.data : e.message
      const code = e.response?.status ? e.response?.status : 400
      return isRefresh ? await this.get(url, params) : { type: 'error', code, error }
    }
    
  }

  async post(url: string, body: object): Promise<any>{
    await this.withRefreshToken();

    try{
      const response = await axios.post(url, body, this.getHeaders())
      return { type: 'success', value: response.data };
    } catch(e: any){
      const isRefresh = await this.isErrorAuth(e);
      const error = e.response ? e.response.data : e.message
      const code = e.response?.status ? e.response?.status : 400
      return isRefresh ? await this.post(url, body) : { type: 'error', code, error }
    }
  }

  async put(url: string, body: object): Promise<any>{
    await this.withRefreshToken();

    try{
      const response = await axios.put(url, body, this.getHeaders())
      return { type: 'success', value: response.data };
    } catch(e: any){
      const isRefresh = await this.isErrorAuth(e);
      const error = e.response ? e.response.data : e.message
      const code = e.response?.status ? e.response?.status : 400
      return isRefresh ? await this.put(url, body) : { type: 'error', code, error }
    }
  }

  async patch(url: string, body: object): Promise<any>{
    await this.withRefreshToken();

    try{
      const response = await axios.patch(url, body, this.getHeaders())
      return { type: 'success', value: response.data };
    } catch(e: any){
      const isRefresh = await this.isErrorAuth(e);
      const error = e.response ? e.response.data : e.message
      const code = e.response?.status ? e.response?.status : 400
      return isRefresh ? await this.patch(url, body) : { type: 'error', code, error }
    }
  }

  async delete(url: string): Promise<any>{
    await this.withRefreshToken();
    
    try{
      const response = await axios.delete(url, this.getHeaders())
      return { type: 'success', value: response.data };
    } catch(e: any){
      const isRefresh = await this.isErrorAuth(e);
      const error = e.response ? e.response.data : e.message
      const code = e.response?.status ? e.response?.status : 400
      return isRefresh ? await this.delete(url) : { type: 'error', code, error }
    }
  }
}

export default ApiController;