import { ICity } from 'domain/types/city.type';
import { isCyrillic, latin_to_cyril } from "utils/translate";

export class GeolocationController {
  private _cities: Array<ICity>;
  private _error: string;
  timeout: number;
  maximumAge: number;

  constructor(
    cities: Array<ICity>
  ){
    this.timeout = 15000;
    this.maximumAge = 60000;
    this._cities = cities;
    this._error = "";
  }

  private async getNameCityByCoordinate(geoLoc: { latitude: any; longitude: any; }) {
    let lat = geoLoc.latitude;
    let lon = geoLoc.longitude;
  
    try {
      const geoResponse = await fetch(
        `https://nominatim.openstreetmap.org/reverse?format=json&lat=${lat}&lon=${lon}&zoom=10&addressdetails=1`
      );
      const geoInfo = await geoResponse.json();
      
      let city = geoInfo.address.city;
      if (!isCyrillic(city)) city = latin_to_cyril(city);
  
      return city;
    } catch (error: any) {
      console.log(error.message)
      throw new Error(`Error: ${error.message}`);
    }
  }

  private async geolocationSuccess(position: { coords: any; }){
    const name = await this.getNameCityByCoordinate(position.coords)

    const cities = this._cities;
    const city = cities.filter((i: { name: any; }) => i.name === name);

    if (!city.length) this._error = `Город ${name} нет в нашем списке! 
      Это значит, что в указанном городе на данный момент нет зарегистрированных заведений`;

    return {
      city: city[0],
      error: this._error
    };
  }
  
  private geolocationFailure(positionError: { code: string | number; }){
    if (+positionError.code === 1) {
      this._error = "Вы запретили браузеру предоставлять ваши данные о своем местоположении. Разрешить доступ можно только в настройках браузера.";
    } else if (+positionError.code === 2) {
      this._error = "Проблемы с сетью или нельзя связаться со службой определения местоположения по каким-либо другим причинам.";
    } else if (+positionError.code === 3) {
      this._error = "He удалось определить местоположение в течение установленного времени.";
    } else {
      this._error = "Приносим свои извинения! Произошла ошибка на сервере.";
    }

    return {
      city: undefined,
      error: this._error
    };
  }

  async getCity(){
    this._error = "";

    const params = {
      timeout: this.timeout,
      maximumAge: 60000,
    }

    let promise = new Promise((resolve) => {
      setTimeout(() => resolve("готово!"), 3000)
    });

    await promise;

    const city = 
      await new Promise((resolve, reject) => navigator.geolocation.getCurrentPosition(resolve, reject, params))
        .then((r: any)=> this.geolocationSuccess(r))
        .catch((e: any) => this.geolocationFailure(e));

    return city;
  }

  async getCoordinates(){
    this._error = "";

    const params = {
      timeout: this.timeout,
      maximumAge: 60000,
    }

    let promise = new Promise((resolve) => {
      setTimeout(() => resolve("готово!"), 1500)
    });

    await promise;

    return await new Promise((resolve, reject) => navigator.geolocation.getCurrentPosition(resolve, reject, params))
      .then((r: any)=> r)
      .catch((e: any) => e);
  }
}

export default GeolocationController;
