import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import MenuSidebar from "./navigations";
import { faBars, faTimes } from "@fortawesome/free-solid-svg-icons";

import styles from "./styles.module.css";

export const MenuNav: React.FC = () => {
  const [ visible, setVisibleMenu ] = useState(false);

  return (
    <>
      <div className={styles['nav_menu']}>
        <FontAwesomeIcon 
          icon={faBars} 
          onClick={()=> setVisibleMenu(true)} 
          className={visible ? styles['nav_icon_hidden'] : styles['nav_icon_visible']}
        />

        <FontAwesomeIcon 
          icon={faTimes} 
          onClick={()=> setVisibleMenu(false)} 
          className={visible ? styles['nav_icon_visible'] : styles['nav_icon_hidden']}
        />
      </div>

      <MenuSidebar visible={visible} setVisible={setVisibleMenu} />
    </>
  );
}

export default MenuNav;