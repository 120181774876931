import axios from "axios";
import jwt_decode from "jwt-decode";

export class AuthController {
  sleep(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  setTokenStorage(tokens: {access_token: string, refresh_token: string}) {
    localStorage.setItem("access_token", tokens.access_token);
    localStorage.setItem("refresh_token", tokens.refresh_token);
  }

  clearTokens() {
    localStorage.removeItem("access_token");
    localStorage.removeItem("refresh_token");
  }

  getAccessTokenStorage(): string | null {
    let token = null;

    if (localStorage.getItem("access_token")) {
      token = localStorage.getItem("access_token");
    }

    return token;
  }

  getRefreshTokenStorage(): string | null {
    let token = null;

    if (localStorage.getItem("refresh_token")) {
      token = localStorage.getItem("refresh_token");
    }

    return token;
  }

  checkedAccessToken(): boolean | null {
    const token = this.getAccessTokenStorage()
    if(!token) return null;
    
    const decoded = jwt_decode(token) as { exp: number };
    return Date.now() <= (decoded.exp * 1000) - 1000 ;
  }

  async refreshAccessToken(){
    const token = this.getRefreshTokenStorage();
    const body = { refresh_token: token };

    const response = await axios.post('/api/auth/refresh', body)

    this.setTokenStorage(response.data.payload);
  }
}
