import moment from "moment";

export function toDate(date) {
  if (!date) return "-";

  return moment(date).format("DD.MM.YYYY");
}

export function toDateMonth(date) {
  if (!date) return "-";

  moment.locale('ru', {
    months : 'января_февраля_марта_апреля_мая_июня_июля_августа_сентября_октября_ноября_декабря'.split('_'),
  });
  return moment(date).format("D MMMM YYYY");
}

export function toDateNoTime(date) {
  if (!date) return "-";

  return moment(date).format('ll');
}

export function toTime(date) {
  if (!date) return "-";

  return moment(date).format("HH:mm:ss");
}