import React from "react";
import { connect } from "react-redux";
import { showWindow } from "domain/actions/auth.action";
import Content from "./layouts/content.layout";

import { Dialog } from "primereact/dialog";

import styles from "../../style.module.css";

const AuthWindow: React.FC = (props: any) => {
  const { isShow, showWindow } = props;

  return (
    <Dialog
      className={styles.authWindow + " " + styles.authorization}
      header="Авторизация"
      visible={isShow}
      onHide={() => showWindow('auth', false)}
      modal
      draggable={false}
    >
      <Content />
    </Dialog>
  );
}

const mapStateToProps = (state: any) => ({
  isShow: state.auth.windows.auth
})

export default connect(mapStateToProps, {showWindow})(AuthWindow);
