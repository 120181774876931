import { combineReducers } from 'redux'
import * as constant from "domain/constants/owner.constant";
import * as types from "domain/types/owner.type";
import { IRestaurant } from 'domain/types/restaurant.type';
import { IQuestion, ITask } from 'domain/types/secretShopper.type';

const initialState = {
  restaurants: [],
  restaurant: {
    questionnaires: [],
    questionnaire: null
  },
  bids: [],
  coupons: [],
  orders: [],
  questionnaires: [],
  questions: [],
  currentQuestionnaire: null,
  selectedQuestionnaire: null,
  current: null,
  coupon: null,
  task: null,
  currentBid: null,
  qrData: null,
  windows: {
    schemaQuestionnaire: false,
    createQuestionnaire: false,
    viewQuestionnaire: false,
    createRestaurant: false,
    createRestaurantHelp: false,
    createBid: false,
    deleteImage: false,
    task: false,
    qrPay: false,
    qrStatus: false,
    coupon: false,
    restaurantQuestionnaires: false,
    questionnaire: false,
  },
  request: {
    loading: false,
    qr: false,
    error: null,
    success: null
  }
}


export function restaurants(state = initialState.restaurants, action: types.OwnerReduceTypes): Array<IRestaurant>{
  switch (action.type) {
    case constant.OWNER_REDUCE_SET_RESTAURANTS:
      return action.payload.data;
    default:
      return state;
  }
}

export function restaurant(state = initialState.restaurant, action: types.OwnerRestaurantReduceTypes) {
  switch (action.type) {
    case constant.OWNER_RESTAURANT_REDUCE_SET_QUESTIONNAIRES:
      return {
        ...state,
        questionnaires: action.payload.data
      };
    case constant.OWNER_RESTAURANT_REDUCE_SET_QUESTIONNAIRE_BY_ID:
      return {
        ...state,
        questionnaire: action.payload.data
      };
    default:
      return state;
  }
}

export function orders(state = initialState.orders, action: types.OwnerReduceTypes): Array<types.IOrder>{
  switch (action.type) {
    case constant.OWNER_REDUCE_SET_ORDERS:
      return action.payload.data;
    default:
      return state;
  }
}

export function questionnaires(state = initialState.questionnaires, action: types.OwnerReduceTypes): Array<types.ISchema>{
  switch (action.type) {
    case constant.OWNER_REDUCE_SET_QUESTIONNAIRES:
      return action.payload.data;
    default:
      return state;
  }
}

export function questions(state = initialState.questions, action: types.OwnerReduceTypes): Array<IQuestion>{
  switch (action.type) {
    case constant.OWNER_REDUCE_SET_QUESTIONS:
      return action.payload.data;
    default:
      return state;
  }
}


export function bids(state = initialState.bids, action: types.OwnerReduceTypes): any{
  switch (action.type) {
    case constant.OWNER_REDUCE_SET_BIDS:
      return action.payload.data;
    default:
      return state;
  }
}

export function coupons(state = initialState.coupons, action: types.OwnerReduceTypes): any{
  switch (action.type) {
    case constant.OWNER_REDUCE_SET_COUPONS:
      return action.payload.data;
    default:
      return state;
  }
}

export function currentBid(state = initialState.currentBid, action: types.OwnerReduceTypes): any {
  switch (action.type) {
    case constant.OWNER_REDUCE_SELECT_BID:
      return action.payload.bid;
    default:
      return state;
  }
}

export function currentQuestionnaire(state = initialState.currentQuestionnaire, action: types.OwnerReduceTypes): types.ISchema | null {
  switch (action.type) {
    case constant.OWNER_REDUCE_SET_CURRENT_QUESTIONNAIRE:
      return action.payload.questionnaire;
    default:
      return state;
  }
}

export function selectedQuestionnaire(state = initialState.selectedQuestionnaire, action: types.OwnerReduceTypes): types.ISchema | null {
  switch (action.type) {
    case constant.OWNER_REDUCE_SELECTED_QUESTIONNAIRE:
      return action.payload.questionnaire;
    case constant.OWNER_REDUCE_CLEAR_QUESTIONNAIRE:
      return null;
    default:
      return state;
  }
}

export function current(state = initialState.current, action: types.OwnerReduceTypes): IRestaurant | null {
  switch (action.type) {
    case constant.OWNER_REDUCE_SELECT_RESTAURANT:
      return action.payload.restaurant;
    default:
      return state;
  }
}

export function coupon(state = initialState.coupon, action: types.OwnerReduceTypes): types.ICoupon | null {
  switch (action.type) {
    case constant.OWNER_REDUCE_SELECT_COUPON:
      return action.payload.data;
    default:
      return state;
  }
}

export function task(state = initialState.task, action: types.OwnerReduceTypes): ITask | null {
  switch (action.type) {
    case constant.OWNER_REDUCE_SELECT_TASK:
      return action.payload.data;
    default:
      return state;
  }
}

export function qrData(state = initialState.qrData, action: types.OwnerReduceTypes): any | null {
  switch (action.type) {
    case constant.OWNER_REDUCE_SET_QR_DATA_PAY:
      return action.payload.data;
    case constant.OWNER_REDUCE_CLEAR_QR_DATA:
      return null;
    default:
      return state;
  }
}

export function windows(state = initialState.windows, action: types.OwnerReduceTypes): types.IOwnerWindows {
  switch (action.type) {
    case constant.OWNER_REDUCE_IS_SHOW_WINDOW:
      const { window, isShow } = action.payload;
      return {
        ...state,
        [window]: isShow
      };
    default:
      return state;
  }
}

export function request(state = initialState.request, action: types.OwnerReduceTypes): any {
  switch (action.type) {
    case constant.OWNER_CHECKOUT_REQUEST:
      return {
        ...state,
        loading: action.payload.loading,
      };
    case constant.OWNER_CHECKOUT_REQUEST_QR:
      return {
        ...state,
        qr: action.payload.loading,
      };
    case constant.OWNER_CHECKOUT_REQUEST_SUCCESS:
      return {
        ...state,
        loading: false,
        success: action.payload.success,
      };
    case constant.OWNER_CHECKOUT_REQUEST_ERROR:
      return {
        loading: false,
        success: null,
        error: action.payload.error,
      };
    default:
      return state;
  }
}

export function getRestaurants(state: { owner: {restaurants: Array<IRestaurant>}; }) {
  return state.owner.restaurants
}

export function getCurrentRestaurant(state: { owner: {current: IRestaurant | null} ; }){
  return state?.owner.current
}

export default combineReducers({
  restaurants,
  restaurant,
  orders,
  bids,
  questionnaires,
  currentQuestionnaire,
  selectedQuestionnaire,
  questions,
  coupons,
  current,
  currentBid,
  task,
  coupon,
  qrData,
  windows,
  request
})