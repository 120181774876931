import React from "react";
import { connect } from 'react-redux';
import { showWindow, login } from 'domain/actions/auth.action';
import { IAuthForm } from "domain/types/auth.type";
import { Form, Field } from "react-final-form";
import { PasswordField, PhoneField } from "components/form/fields";
import {
  requiredValidator,
  composeValidators,
  phoneValidator,
} from "components/form/validators";

import FooterComponent from "./footer.layout";

import styles from "../../../style.module.css";
import { Button } from "primereact/button";
import { Divider } from 'primereact/divider';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faVk } from "@fortawesome/free-brands-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

interface IProps {
  isPage?: boolean;
  showPage?: (name: string) => void;
}

export const FormComponent: React.FC<IProps> = (props: any) => {
  let formData = {
    login: "",
    password: "",
    remember: false,
  };

  const { login, error, showWindow } = props;
  const { isPage = false, showPage = () => {}} = props;

  const handleRestore = () => showWindow('restore', true);
  const onSubmit = (values: IAuthForm) => login(values);
  const handleGoogle = (e: any) => {
    e.preventDefault();
    const url_redirect = process.env.REACT_APP_URL_GOOGLE_AUTH;
    if(url_redirect) window.location.replace(url_redirect);
  }
  const handleVkAuth = (e: any) => {
    e.preventDefault();
    const url_redirect = process.env.REACT_APP_URL_VK_AUTH;
    if(url_redirect) window.location.replace(url_redirect);
  }

  return (
    <div className="p-col-12 p-pr-0 p-pl-0 p-pt-0">
      {error && (
        <span style={{ color: "red", fontSize: "12px" }}>
          Неверный логин или пароль
        </span>
      )}
      <FooterComponent isPage={isPage} showPage={showPage} />
      <br/>
      <Form
        onSubmit={onSubmit}
        initialValues={formData}
        render={({ handleSubmit, submitting }) => (
          <form onSubmit={handleSubmit}>
            <div className="p-mt-1 p-mb-2">
              <Field
                validate={composeValidators(requiredValidator, phoneValidator)}
                name="login"
                label="Номер телефона:"
                render={PhoneField}
                settings={{
                  placeholder: "Введите свой номер телефона",
                  mask: "+00000000000",
                }}
              />
            </div>

            <div className="p-mt-1 p-mb-2">
              <Field
                validate={requiredValidator}
                name="password"
                label="Пароль:"
                render={PasswordField}
                settings={{
                  placeholder: "Введите пароль",
                  feedback: false,
                  toggleMask: true
                }}
                icon={["fas", "lock"]}
              />
            </div>

            <div className="p-col-12 p-p-0 p-mt-2 p-mb-3">
              <span className={styles.reload_pass} onClick={handleRestore}>Восстановить пароль</span>
            </div>

            <div className="p-mt-2">
              <button
                type="submit"
                className={styles.form_button}
                disabled={submitting}
              >
                Войти
              </button>
            </div>

            <Divider />

            <Button className="google p-p-0 p-col-12" onClick={handleGoogle}>
              <i className="pi pi-google"></i>
              <span className="p-p-3" style={{width: '100%'}}>Войти через <strong>Google</strong></span>
            </Button>

            <Button className="vk p-p-0 p-col-12 p-mt-2" onClick={handleVkAuth}>
              {/* <i> <FontAwesomeIcon icon={["fab", "vk"]} /> </i> */}
              <i> <FontAwesomeIcon icon={faVk as IconProp} /> </i>
              <span className="p-p-3" style={{width: '100%'}}>Войти через <strong>ВКонтакте</strong></span>
            </Button>
          </form>
        )}
      />
    </div>
  );
}

const mapStateToProps = (state: any) => ({
  error: state.auth.request.error
})

export default connect(mapStateToProps, {showWindow, login})(FormComponent);