import { ITask } from './../types/secretShopper.type';
import * as constant from "domain/constants/restaurant.constant";
import * as types from "domain/types/restaurant.type";

// Sagas
export function getRestaurants(
  page?: number, 
  isLoading?: boolean, 
  pageSize?: number
): types.RestaurantsSagaTypes{
  return {
    type: constant.RESTAURANTS_SAGA_GET_ALL_CITY,
    payload: { 
      page: page ? page : 1, 
      isLoading: isLoading !== undefined ? isLoading : true ,
      pageSize: pageSize,
    },
  }
}

export function getSimilarRestaurants(restaurantId: string): types.RestaurantsSagaTypes{
  return {
    type: constant.RESTAURANTS_SAGA_GET_SIMILAR,
    payload: { restaurantId },
  }
}

export function searchLikeRestaurants(
  isLoading?: boolean, 
): types.RestaurantsSagaTypes{
  return {
    type: constant.RESTAURANTS_SAGA_SEARCH_BY_LIKE,
    payload: { 
      isLoading: isLoading !== undefined ? isLoading : true
    },
  }
}

export function getTasksShopper(restaurantId: string): types.RestaurantsSagaTypes{
  return {
    type: constant.RESTAURANTS_SAGA_GET_TASKS_CURRENT,
    payload: { restaurantId },
  }
}


export function getByIdRestaurant(restaurantId: string): types.RestaurantsSagaTypes{
  return {
    type: constant.RESTAURANTS_SAGA_GET_BY_ID,
    payload: { restaurantId }
  }
}

export function getCategories(){
  return {
    type: constant.RESTAURANTS_SAGA_GET_CATEGORIES
  }
}

export function getCategoriesDishes(){
  return {
    type: constant.RESTAURANTS_SAGA_GET_CATEGORIES_DISHES
  }
}

export function getTagsDishes(){
  return {
    type: constant.RESTAURANTS_SAGA_GET_TAGS_DISHES
  }
}

export function getCategoriesHookah(){
  return {
    type: constant.RESTAURANTS_SAGA_GET_CATEGORIES_HOOKAH
  }
}

export function getKitchenDishes(){
  return {
    type: constant.RESTAURANTS_SAGA_GET_KITCHEN_DISHES
  }
}

export function createBidMy(values: types.IRestaurantBidMy){
  return {
    type: constant.RESTAURANTS_SAGA_POST_BID_MY,
    payload: {values}
  }
}

export function getStocksRestaurants(){
  return {
    type: constant.RESTAURANTS_SAGA_GET_STOCKS,
  }
}

// Reducers
export function setRestaurants(restaurants: Array<types.IRestaurant> ): types.RestaurantsReduceTypes{
  return {
    type: constant.RESTAURANTS_REDUCE_SET_ALL_CITY,
    payload: { data: restaurants }
  }
}

export function setVerifiedRestaurants(restaurants: Array<types.IRestaurant> ): types.RestaurantsReduceTypes{
  return {
    type: constant.RESTAURANTS_REDUCE_SET_VERIFIED,
    payload: { data: restaurants }
  }
}

export function setSimilar(restaurants: types.IRestaurant[]): types.RestaurantsReduceTypes {
  return {
    type: constant.RESTAURANTS_REDUCE_SET_SIMILAR,
    payload: { data: restaurants }
  }
}

export function setTasks(tasks: ITask[]): types.RestaurantsReduceTypes {
  return {
    type: constant.RESTAURANTS_REDUCE_SET_TASKS_CURRENT,
    payload: { data: tasks }
  }
}

export function setTopRestaurants(restaurants: Array<types.IRestaurant>): types.RestaurantsReduceTypes{
  return {
    type: constant.RESTAURANTS_REDUCE_SET_TOP_CITY,
    payload: { data: restaurants }
  }
}

export function setCategories(categories: any): any {
  return {
    type: constant.RESTAURANTS_REDUCE_SET_CATEGORIES,
    payload: { categories }
  }
}

export function setLike(like: string){
  return {
    type: constant.RESTAURANTS_REDUCE_SET_FILTER_LIKE,
    payload: { like }
  }
}

export function setCategoriesDish(categories: any): any {
  return {
    type: constant.RESTAURANTS_REDUCE_SET_CATEGORIES_DISHES,
    payload: { categories }
  }
}

export function setAllCountRestaurants(count: number): any {
  return {
    type: constant.RESTAURANTS_REDUCE_SET_ALL_COUNT,
    payload: { count }
  }
}

export function setCategoriesHookah(categories: any): any {
  return {
    type: constant.RESTAURANTS_REDUCE_SET_CATEGORIES_HOOKAH,
    payload: { categories }
  }
}

export function setKitchens(kitchens: any): any {
  return {
    type: constant.RESTAURANTS_REDUCE_SET_KITCHENS_DISHES,
    payload: { kitchens }
  }
}

export function setTagsDishes(tags: any): any {
  return {
    type: constant.RESTAURANTS_REDUCE_SET_TAGS_DISHES,
    payload: { tags }
  }
}

export function selectRestaurant(restaurant: types.IRestaurant): types.RestaurantsReduceTypes{
  return {
    type: constant.RESTAURANTS_REDUCE_SELECT,
    payload: { restaurant }
  }
}

export function getStocksFilter(
  page?: number, 
  isLoading?: boolean, 
  pageSize?: number
){
  return {
    type: constant.RESTAURANTS_SAGA_GET_STOCKS_FILTER,
    payload: { 
      page: page ? page : 1, 
      isLoading: isLoading !== undefined ? isLoading : true ,
      pageSize: pageSize,
    },
  }
}

export function getByFilterRestaurant(): types.RestaurantsReduceTypes{
  return {
    type: constant.RESTAURANTS_REDUCE_SET_BY_FILTERS,
  }
}

export function setFilterCategories(categoryId: string): types.RestaurantsReduceTypes{
  return {
    type: constant.RESTAURANTS_REDUCE_SET_FILTER_CATEGORIES,
    payload: {
      categoryId: categoryId
    }
  }
}

export function deleteFilterCategories(categoryId: string): types.RestaurantsReduceTypes{
  return {
    type: constant.RESTAURANTS_REDUCE_DELETE_FILTER_CATEGORIES,
    payload: {
      categoryId: categoryId
    }
  }
}

export function clearFilterCategories(): types.RestaurantsReduceTypes{
  return {
    type: constant.RESTAURANTS_REDUCE_CLEAR_FILTER_CATEGORIES,
  }
}

export function setFilterHashtags(hashtagId: string): types.RestaurantsReduceTypes{
  return {
    type: constant.RESTAURANTS_REDUCE_SET_FILTER_HASHTAGS,
    payload: {
      hashtagId: hashtagId
    }
  }
}

export function deleteFilterHashtags(hashtagId: string): types.RestaurantsReduceTypes{
  return {
    type: constant.RESTAURANTS_REDUCE_DELETE_FILTER_HASHTAGS,
    payload: {
      hashtagId: hashtagId
    }
  }
}

export function clearFilterHashtags(): types.RestaurantsReduceTypes{
  return {
    type: constant.RESTAURANTS_REDUCE_CLEAR_FILTER_HASHTAGS,
  }
}

export function setFilterAdding(addingId: string): types.RestaurantsReduceTypes{
  return {
    type: constant.RESTAURANTS_REDUCE_SET_FILTER_ADDING,
    payload: {
      addingId: addingId
    }
  }
}

export function deleteFilterAdding(addingId: string): types.RestaurantsReduceTypes{
  return {
    type: constant.RESTAURANTS_REDUCE_DELETE_FILTER_ADDING,
    payload: {
      addingId: addingId
    }
  }
}

export function clearFilterAdding(): types.RestaurantsReduceTypes{
  return {
    type: constant.RESTAURANTS_REDUCE_CLEAR_FILTER_ADDING,
  }
}

export function setFilterSpecial(specialId: string): types.RestaurantsReduceTypes{
  return {
    type: constant.RESTAURANTS_REDUCE_SET_FILTER_SPECIAL,
    payload: {
      specialId: specialId
    }
  }
}

export function deleteFilterSpecial(specialId: string): types.RestaurantsReduceTypes{
  return {
    type: constant.RESTAURANTS_REDUCE_DELETE_FILTER_SPECIAL,
    payload: {
      specialId: specialId
    }
  }
}

export function clearFilterSpecial(): types.RestaurantsReduceTypes{
  return {
    type: constant.RESTAURANTS_REDUCE_CLEAR_FILTER_SPECIAL,
  }
}

export function setFilterAverage(range: [number, number]): types.RestaurantsReduceTypes{
  return {
    type: constant.RESTAURANTS_REDUCE_SET_FILTER_AVERAGE,
    payload: {
      range: range
    }
  }
}

export function clearFilterAverage(): types.RestaurantsReduceTypes{
  return {
    type: constant.RESTAURANTS_REDUCE_CLEAR_FILTER_AVERAGE,
  }
}

export function setSorts(sort: string, indicator: string | undefined): any {
  return {
    type: constant.RESTAURANTS_REDUCE_SET_SORTS,
    payload: { sort, indicator }
  }
}

export function setPaginationPage(page: number): types.RestaurantsReduceTypes{
  return {
    type: constant.RESTAURANTS_REDUCE_SET_PAGINATION_PAGE,
    payload: { page }
  }
}

export function showWindow(window: string, isShow: boolean ): types.RestaurantsReduceTypes {
  return {
    type: constant.RESTAURANTS_REDUCE_IS_SHOW_WINDOW,
    payload: {
      window: window,
      isShow: isShow
    }
  }
}

export function reqRestaurants(isLoad: boolean): types.RestaurantsReduceTypes{
  return {
    type: constant.RESTAURANTS_CHECKOUT_REQUEST,
    payload: { loading: isLoad }
  }
}

export function reqErrorCity(error: any): types.RestaurantsReduceTypes{
  return {
    type: constant.RESTAURANTS_CHECKOUT_REQUEST_ERROR,
    payload: { error }
  }
}
