import { call, fork, takeEvery } from 'redux-saga/effects'
import ApiController from 'domain/controllers/Api.controller';
import * as constants from "domain/constants/notification.constant";
import { Result } from 'domain/types/other.type';
import { NorrController } from 'domain/controllers/Response.controller';
import { fetchToken } from 'firebaseInit';

const api = new ApiController();
const norr = new NorrController();

export async function postNotifyingFetch(): Promise<Result<any>> {
  return await api.post(`/notifications/test`, {})
}

export async function postTokenFetch(token: string): Promise<Result<any>> {
  return await api.post(`/notifications/token`, { token })
}

export async function checkTokenNotificationFetch(accountId: string): Promise<Result<any>> {
  return await api.get(`/notifications/check/${accountId}`)
}

export function* checkTokenNotification(accountId: string): any{
  const response = yield call(checkTokenNotificationFetch, accountId)
  
  yield call(norr.processing, response, function *(){
    if(!response.value) yield call(postToken)
  })
}

export function* postNotifying(): any{
  const response = yield call(postNotifyingFetch)
  yield call(norr.processing, response, function *(){}, "Уведомление отправлено")
}

export function* postToken(): any{
  const token = yield call(fetchToken);
  if(!token) return ;
  const response = yield call(postTokenFetch, token)
  yield call(norr.processing, response, function *(){})
}

export function* watch() {
  yield takeEvery(constants.NOTIFICATION_SAGA_POST, postNotifying)
  yield takeEvery(constants.NOTIFICATION_SAGA_POST_TOKEN, postToken)
}

export default function* NotificationsSagas() {
  yield fork(watch)
}