import { combineReducers } from 'redux'
import * as constant from "domain/constants/city.constant";
import * as types from "domain/types/city.type";
import { IStatusResponse } from 'domain/types/status.type';
import { load } from 'redux-localstorage-simple';

const savedReduce: any = load();

const initialState = {
  cities: [],
  fasting: [],
  current: (savedReduce && savedReduce.cities && savedReduce.cities.current)
    ? savedReduce.cities.current : null,
  geoError: "",
  request: {
    loading: false,
    error: null
  },
  geolocationLoad: false
}

export function cities(state = initialState.cities, action: types.CitiesReduceTypes): Array<types.ICity> {
  switch (action.type) {
    case constant.CITIES_REDUCE_SET_ALL:
      return action.payload.data;
    default:
      return state;
  }
}

export function fasting(state = initialState.fasting, action: types.CitiesReduceTypes): Array<types.ICity> {
  switch (action.type) {
    case constant.CITIES_REDUCE_SET_DEFAULT:
      return action.payload.data;
    default:
      return state;
  }
}

export function current(state = initialState.current, action: types.CitiesReduceTypes): types.ICity | null {
  switch (action.type) {
    case constant.CITIES_REDUCE_SELECT:
      return action.payload.city;
    default:
      return state;
  }
}

export function geoError(state = initialState.geoError, action: types.CitiesReduceTypes): string {
  switch (action.type) {
    case constant.CITIES_REDUCE_SET_ERROR_GEOLOCATION:
      return action.payload.error;
    default:
      return state;
  }
}

export function request(state = initialState.request, action: types.CitiesReduceTypes): IStatusResponse {
  switch (action.type) {
    case constant.CITIES_CHECKOUT_REQUEST:
      return {
        error: null,
        loading: action.payload.loading,
      };
    case constant.CITIES_CHECKOUT_REQUEST_ERROR:
      return {
        loading: false,
        error: action.payload.error,
      };
    default:
      return state;
  }
}

export function geolocationLoad(state = initialState.geolocationLoad, action: types.CitiesReduceTypes): boolean {
  switch (action.type) {
    case constant.GEOLOCATION_CHECKOUT_REQUEST:
      return action.payload.loading;
    default:
      return state;
  }
}

export function getCities(state: { cities: {cities: Array<types.ICity>}; }) {
  return state.cities.cities
}

export function getCurrentCity(state: { cities: {current: types.ICity | null} ; }){
  return state?.cities.current
}

export function getErrorStops(state: { stops: {request: types.CitiesReduceTypes} ; }){
  return state.stops.request
}

export default combineReducers({
  cities,
  fasting,
  current,
  geoError,
  request,
  geolocationLoad
})