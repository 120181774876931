import * as constant from "domain/constants/reviews.constant";
import * as types from "domain/types/reviews.type";

// Sagas
export function getReviews(): types.ReviewsSagaTypes{
  return {
    type: constant.REVIEWS_SAGA_GET_ALL,
  }
}

export function postReview(review: types.IReview, cb: () => void): types.ReviewsSagaTypes{
  return {
    type: constant.REVIEWS_SAGA_POST,
    payload: { review, cb }
  }
}

export function putReview(review: types.IReview, cb: () => void): types.ReviewsSagaTypes{
  return {
    type: constant.REVIEWS_SAGA_PUT,
    payload: { review, cb }
  }
}

// Reducers
export function setReviews(reviews: types.IReview[] ): types.ReviewsReduceTypes{
  return {
    type: constant.REVIEWS_REDUCE_SET_ALL,
    payload: { data: reviews }
  }
}

export function reqReviews(isLoad: boolean): types.ReviewsReduceTypes{
  return {
    type: constant.REVIEWS_CHECKOUT_REQUEST,
    payload: { loading: isLoad }
  }
}