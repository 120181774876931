import React, { useEffect } from "react";
import Header from "./layouts/header";
import Filter from "./layouts/filter";
import Banner from "./layouts/banner";
import Events from "./layouts/events";
import RecentViews from "./layouts/recentViews";
import About from "./layouts/about";
import Footer from "./layouts/footer";
import Reviews from "./layouts/reviews";

import { WrapperProgress } from "components/wrapperProgress";
import { useSearchParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { showWindow, setRedirect } from "domain/actions/auth.action";


export const StartPage: React.FC = (props: any) => {
  const dispatch = useDispatch();
  let [searchParams] = useSearchParams()

  useEffect(() => {
    const login = searchParams.get("login")
    const redirect = searchParams.get("redirect")
    if(login === 'true') {
      dispatch(showWindow('auth', true))
      if(redirect) dispatch(setRedirect(redirect))
    }
  }, [searchParams])

  return (
    <WrapperProgress timer={500}>
      <div className='main_container'>
        <Header />

        <Filter />

        <Banner />

        <Events />

        <RecentViews />

        <Reviews />

        <About />

        <Footer />
      </div>
    </WrapperProgress>
  )
}

export default StartPage;