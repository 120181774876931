import React from "react";
import { connect } from "react-redux";

import styles from "../styles.module.css";


export const Banner: React.FC = () => {
  
  return (
    <div className={styles['banner']}>
      <a target="_blank" rel="noopener noreferrer" 
          href='https://landing.3raza.ru' 
          className={styles['banner_btn']}
        >
      <div className={styles['banner_image']}></div>
      </a>
      <div className={styles['banner_info']}>
        <h2>Все об участии в розыгрыше <em><strong>“Тайный покупатель”</strong></em></h2>
        <span className='p-d-none p-d-md-block'>Подробная информация о данной функции и условия/правила выполнения заданий.</span>
        <a target="_blank" rel="noopener noreferrer" 
          href='https://landing.3raza.ru' 
          className={styles['banner_btn']}
        >Подробнее</a>
      </div>
    </div>
  );
}


export default connect()(Banner);