import { call, fork, takeEvery } from 'redux-saga/effects'
import BillingController from 'domain/controllers/Billing.controller';
import * as constants from "domain/constants/billing.constant";
import { Result } from 'domain/types/other.type';
import { NorrController } from 'domain/controllers/Response.controller';
import { getCoupons } from './owner.saga';

const billing = new BillingController();
const norr = new NorrController();

export async function refreshOrderFetch(orderId: string): Promise<Result<void>> {
  return await billing.put(`/account/coupons/${orderId}/refresh`, {})
}

export async function replayOrderFetch(orderId: string, returnUrl: string): Promise<Result<string>> {
  return await billing.put(`/account/coupons/${orderId}/replay`, { returnUrl })
}

export async function getPayoutFetch(): Promise<Result<any>> {
  // return await billing.get(`/psb/sbp/qr/${qrId}`)
  return await billing.get(`/orders/status/123`)
}



export function* refreshOrder(action: any): any{
  const orderId = action.payload.orderId;

  const response = yield call(refreshOrderFetch, orderId)

  yield call(norr.processing, response, function *(){
    yield call(getCoupons)
  }, "Успешно, статусы обновлены")
}

export function* replayOrder(action: any): any{
  const orderId = action.payload.orderId;
  const url = document.location.href

  const response = yield call(replayOrderFetch, orderId, url)

  yield call(norr.processing, response, function *(){
    yield window.location.replace(response.value);
  })
}

export function* replayQr(action: any): any{
  yield window.open(action.payload.url, '_blank');
}

export function* watchAccount() {
  yield takeEvery(constants.BILLING_SAGA_REFRESH_ORDER, refreshOrder)
  yield takeEvery(constants.BILLING_SAGA_REPLAY_ORDER, replayOrder)
  yield takeEvery(constants.BILLING_SAGA_REPLAY_QR, replayQr)
}

export default function* AccountSagas() {
  yield fork(watchAccount)
}