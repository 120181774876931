import React, { useEffect } from "react";
import { connect } from "react-redux";

import styles from "../styles.module.css";
import { getReviews } from "domain/actions/review.action";
import ReviewsGalleria from "./review.gallery";

export const Reviews: React.FC = (props: any) => {
  const { reviews, getReviews } = props;

  useEffect( () => {
    getReviews()
  }, [] ) // eslint-disable-line react-hooks/exhaustive-deps

  if(!reviews.length) return <></>
  
  return (
    <div className={styles['events'] + ' block'}>
      <h2 className={styles['title']} id="events" >Отзывы наших пользователей</h2>
  
      <ReviewsGalleria data={reviews} />
    </div>
  );
}

const mapStateToProps = (state: any) =>({
  reviews: state.reviews.data
});

export default connect(mapStateToProps, { getReviews } )(Reviews);