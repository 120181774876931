import React, { useRef } from "react";
import { InputText } from 'primereact/inputtext';
import { FieldRenderProps } from "react-final-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Tooltip } from 'primereact/tooltip';

import styles from "../styles.module.css";

type FieldPropsType = FieldRenderProps<string, any>;

export const TextField = (props: FieldPropsType) => {
  const { meta, icon, input, label, settings, help, style={} } = props;
  const ref = useRef<any>(null);

  const focus = () => {
    if(null !== ref && null !== ref.current) {
      ref.current.focus();
    }
  }

  return (
    <React.Fragment>
      <div
        className={`${styles.input} ${
          meta.error && meta.touched && styles.input_error
        }`} style={style}

        onClick={ focus }
      >
        <label>
          {label}
          
          {
            help && 
            <>
              <Tooltip target={`.help-${input.name}`} mouseTrack mouseTrackLeft={10} style={{fontSize: '12px'}}/>
              <FontAwesomeIcon icon={["fas", "question-circle"]} className={`help-${input.name} p-ml-1`} data-pr-tooltip={help}/>
            </>
          }
        </label>
        <div className={styles.inputWrapper}>
          <InputText {...input} {...settings} ref={ref} />
          {icon && <FontAwesomeIcon icon={icon} />}
        </div>
        {meta.error && meta.touched && (
          <small className={styles.error}>{meta.error}</small>
        )}
      </div>
    </React.Fragment>
  );
}