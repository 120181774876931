export const AUTH_CHECKING = "AUTH_CHECKING";

export const AUTH_SUCCESS = "AUTH_SUCCESS";
export const AUTH_REJECT = "AUTH_REJECT";

export const AUTH_ACTION_LOGIN = "AUTH_ACTION_LOGIN";
export const AUTH_ACTION_LOGOUT = "AUTH_ACTION_LOGOUT";
export const AUTH_ACTION_CHECK_CODE = "AUTH_ACTION_CHECK_CODE";
export const AUTH_ACTION_REGISTRATION = "AUTH_ACTION_REGISTRATION";

export const AUTH_SAGA_RESTORE_PASSWORD = "AUTH_SAGA_RESTORE_PASSWORD";
export const AUTH_SAGA_RESTORE_PASSWORD_CHECKED = "AUTH_SAGA_RESTORE_PASSWORD_CHECKED";

export const AUTH_REDUCE_IS_SHOW_WINDOW = "AUTH_REDUCE_IS_SHOW_WINDOW";

export const AUTH_REDUCE_SET_AUTH = "AUTH_REDUCE_SET_AUTH";
export const AUTH_REDUCE_SET_DATA_RESTORE = "AUTH_REDUCE_SET_DATA_RESTORE";

export const AUTH_CHECKOUT_REQUEST = "AUTH_CHECKOUT_REQUEST";
export const AUTH_CHECKOUT_REQUEST_ERROR = "AUTH_CHECKOUT_REQUEST_ERROR";

export const AUTH_SAGA_REPEAT_CODE = "AUTH_SAGA_REPEAT_CODE";

export const AUTH_REDUCE_SET_REDIRECT = "AUTH_REDUCE_SET_REDIRECT";