import React from "react";

import styles from "../../styles.module.css";
import { useNavigate } from "react-router-dom";
import { IStockRestaurant } from "domain/types/stock.type";
import { toLengthString } from "utils/toLengthString";

interface IProps {
  item: IStockRestaurant | any
}

export const CardStock: React.FC<IProps> = (props: IProps) => {
  const { item } = props;
  let history = useNavigate();

  const redirect = () => {
    if(item?.restaurant) history(`/main/restaurant/${item.restaurant.id}`)
  };

  return (
    <div className="p-p-1 p-col-12">
      <div className={'block ' + styles['card']} >
        
        <div className="p-d-flex p-flex-column" onClick={redirect} style={{cursor: 'pointer'}}>
          <span className="p-p-0" onClick={redirect} style={{cursor: 'pointer'}}><strong>{item.restaurant.name}</strong></span>
          <span className="p-p-0" style={{ fontSize: '11px' }}>
            <i>{item.restaurant.city.name} - {item.restaurant.address}</i>
          </span>

          <div className="p-d-flex p-mt-2">
            <img 
              src={`/api/admin/files/stocks/${item.urlPhoto}`} 
              alt={item.urlPhoto} 
              className={styles['card_image']}
              onClick={redirect}
              style={{cursor: 'pointer'}}
            />

            <span className={styles['card_description'] + ' p-ml-2'}> {toLengthString(item.description, 65)} </span>
          </div>
        </div>

        
      </div>

      
    </div>
  )
}

export default CardStock