import * as constants from "domain/constants/other";
import * as types from "domain/types/other.type";

const initialState = {
  menuSide: false,
}

export function menuSide(state = initialState, action: types.OtherTypes) {
  switch (action.type) {
    case constants.MENU_SIDE_IS_SHOW:
      return {
        menuSide: action.payload.isShow
      };
   
    default:
      return state;
  }
}

export default menuSide;