import React from "react";
import { connect } from "react-redux";
import { showWindow } from "domain/actions/auth.action";

import styles from "../style.module.css";

export const SignInDesktop: React.FC = (props: any) => {
  const login = () => props.showWindow('auth', true);

  return (
    <button onClick={login} className={styles.btn}>
      Войти
    </button>
  );
}

export default connect(null, {showWindow})(SignInDesktop);
