import { combineReducers } from 'redux'
import * as constant from "domain/constants/email.constant";
import * as types from "domain/types/email.type";

const initialState = {
  request: {
    loading: false,
    error: null,
    success: null
  }
}

export function request(state = initialState.request, action: types.EmailReduceTypes): any {
  switch (action.type) {
    case constant.EMAIL_CHECKOUT_REQUEST:
      return {
        ...state,
        error: null,
        loading: action.payload.loading,
      };
    case constant.EMAIL_CHECKOUT_REQUEST_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case constant.EMAIL_CHECKOUT_REQUEST_SUCCESS:
      return {
        ...state,
        loading: false,
        success: action.payload.success,
      };
    default:
      return state;
  }
}

export function getErrorEmail(state: { emails: {request: types.EmailReduceTypes} ; }){
  return state.emails.request
}

export default combineReducers({
  request
})